//hooks
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
//api
import { useAllNews, useSingleNews } from '@api/queries/news/news';
//type
import { FormModalPropsType } from '@components/FormModal/FormModal';
import { getDefaultPagnation, getTranslationValue } from '@helpers/utility';
import { MRT_ColumnFiltersState, MRT_PaginationState } from 'material-react-table';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { useForm } from 'react-hook-form';
import { CreateNewsType, NewsType } from '@interfaces/news/news';
import { newsFields } from '@helpers/formFields/formFields';
import { useDeleteNewsImage, useEditNews } from '@api/mutations/news/news';
import { formHelper } from '@helpers/formDataHelper';
import { useAllRubrics } from '@api/queries/interests/interests';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';
import { useCountries } from '@api/queries/autocomplete/autocomplete';
import { CountryLocalizedType } from '@interfaces/autocomplete/autocomplete';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';

export function useNews() {
  const { newsId } = useParams();

  const [popup, setPopup] = useState<FormModalPropsType<CreateNewsType> | null>();
  const [values, setFormValues] = useState<CreateNewsType | undefined>();
  const { t, i18n } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams({ pageIndex: '0', pageSize: '10' });
  const [pagination, setPagination] = useState<MRT_PaginationState>(
    getDefaultPagnation(searchParams)
  );
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const onDeleteClick = () => {
    setPopup({
      title: t(`global.delete_title`, { object: t(`delete.news`) }),
      subtitle: t(`global.delete_subtitle`, { object: t(`delete.news`) }),
      open: true,
      fields: [],
      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        setPopup(null);
      },
      clearErrors: clearErrors,
      getValues: getValues
    });
  };

  const onSuccessUpdate = (data: NewsType) => {
    const val = getValues();
    const deleteTypes = [];

    if (val.featuredImage === null) deleteTypes.push('featuredImage');
    if (val.heroImage === null) deleteTypes.push('heroImage');

    setInfoPopup({
      title: t('global.success_update'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });

    if (deleteTypes.length === 0) {
      refetchSingle();
      onCancel();
    } else {
      deleteNewsImages({
        id: data.id,
        type: deleteTypes
      });
    }
  };

  const onUpdateError = (err: ErrorType<CreateNewsType>) => {
    setError('root', {
      type: 'custom',
      message: err.message
    });

    if (err.errors)
      for (const key in err.errors) {
        setError(key as keyof CreateNewsType, {
          type: 'custom',
          message: err.errors[key as keyof CreateNewsType]?.join(', ')
        });
      }
  };

  const { data: news, isLoading: isLoadingAll } = useAllNews(onError, {
    page: pagination.pageIndex + 1,
    take: pagination.pageSize,
    search: columnFilters.find(el => el.id === 'title')?.value as string,
    rubricId: columnFilters.find(el => el.id === 'rubrics')?.value as string,
    lang: i18n.language,
    countries: [columnFilters.find(el => el.id === 'countries')?.value as string]
  });

  const {
    data: singleNews,
    isLoading: isLoadingSingle,
    refetch: refetchSingle
  } = useSingleNews(newsId, onError, i18n.language);

  const { data: rubrics } = useAllRubrics(onError);

  const { mutate: updateNews, isLoading: isLoadingUpdate } = useEditNews(
    onSuccessUpdate,
    onUpdateError
  );

  const { mutate: deleteNewsImages, isLoading: isLoadingDelete } = useDeleteNewsImage(
    onError,
    () => {
      refetchSingle();
      onCancel();
    }
  );

  const {
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
    getValues
  } = useForm<CreateNewsType>({
    values
  });

  const onSubmit = (data: CreateNewsType) => {
    if (newsId) {
      updateNews({
        id: newsId,
        data: formHelper.getBody(
          Object.fromEntries(
            Object.entries({
              ...data,
              tags: data.tags?.filter(tag => tag !== '').join(','),
              hashTags: data.hashTags?.filter(tag => tag !== '').join(','),
              rubrics: data.rubrics?.join(','),
              countries: data.countries?.join(',') ?? []
            })
          )
        )
      });
    }
  };

  const onCancel = () => {
    setPopup(null);
    setFormValues({
      title: '',
      shortDescription: '',
      tags: [''],
      hashTags: [''],
      heroImage: undefined,
      featuredImage: undefined
    });
  };

  const onEditClick = () => {
    if (singleNews) {
      const tmp = {
        title: singleNews.title !== null ? singleNews.title : '',
        shortDescription: singleNews.shortDescription !== null ? singleNews.shortDescription : '',
        tags: singleNews.tags ? (singleNews.tags.length > 0 ? singleNews.tags : ['']) : [''],
        hashTags: singleNews.hashTags
          ? singleNews.hashTags.length > 0
            ? singleNews.hashTags
            : ['']
          : [''],
        heroImage: singleNews.heroImage,
        featuredImage: singleNews.featuredImage,
        rubrics: singleNews.rubrics.map(r => r.id),
        countries: singleNews.countries.map(r => r.id)
      };
      setFormValues(tmp);
    }

    setPopup({
      title: t(`news.edit_title`),
      open: true,
      control: control,
      fields: newsFields(
        t,
        rubrics,
        i18n.language,
        setValue,
        !!errors.countries,
        singleNews?.countries.map(c => {
          let country: CountryLocalizedType = {
            id: c.id,
            alpha2: c.alpha2,
            currency: c.currency,
            currencySymbol: c.currencySymbol,
            flag: c.flag,
            visible: c.visible,
            createdAt: c.createdAt,
            updatedAt: c.updatedAt,
            name: getTranslationValue(
              c.translations,
              'name',
              i18n.language as SupportedLanguagesEnum
            )
          };
          return country;
        })
      ),
      onCancel: onCancel,
      buttonText: t('global.edit'),
      variant: 'form',
      onSubmit: handleSubmit(onSubmit),
      setError: setError,
      setValue: setValue,
      clearErrors: clearErrors,
      getValues: getValues
    });
  };

  const [countrySearch, setCountrySearch] = useState<string>('');

  const { data: suggestedCountries, isLoading: isLoadingSuggestion } = useCountries(true, onError, {
    search: countrySearch ?? '',
    lang: i18n.language
  });

  const handleSearchChange = (event: React.SyntheticEvent, value: string) => {
    setCountrySearch(value);
  };

  const handleInputChange = (
    e: React.SyntheticEvent,
    data: CountryLocalizedType | null | string
  ) => {
    if (typeof data !== 'string' && data?.id) {
      setColumnFilters(prev => {
        return [...prev, { id: 'countries', value: data.id }];
      });
    }

    if (data === null) {
      setColumnFilters([]);
    }
  };

  useEffect(() => {
    if (location.pathname === '/news')
      setSearchParams({
        pageIndex: pagination.pageIndex.toString(),
        pageSize: pagination.pageSize.toString()
      });
  }, [pagination]);

  return {
    news: news ? news.data : [],
    singleNews,
    rubrics: rubrics ? rubrics : [],
    popup,
    setPopup,
    onDeleteClick,
    isLoadingAll,
    isLoadingSingle,
    pagination,
    setPagination,
    rowCount: news ? news.meta.itemCount : 0,
    onEditClick,
    columnFilters,
    setColumnFilters,
    setValue,
    clearErrors,
    isSubmitting: isLoadingUpdate || isLoadingDelete,
    countrySearch,
    setCountrySearch,
    suggestedCountries,
    isLoadingSuggestion,
    handleSearchChange,
    handleInputChange
  };
}
