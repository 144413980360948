//hooks
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
//api
import {
  useAllUsers,
  useSingleUser,
  useUsersCelebrations,
  useUsersGenres,
  useUsersIndustries,
  useUsersInterests,
  useUsersRubrics
} from '@api/queries/users/users';
import { useDeleteUser, useExportUsersData } from '@api/mutations/user/user';
//type
import { MRT_ColumnFiltersState, MRT_PaginationState } from 'material-react-table';
import { ExportUsersParamsType, User } from '@interfaces/users/users';
import { FormModalPropsType } from '@components/FormModal/FormModal';
import { ErrorsResponseType } from '@interfaces/response';
import { RoleEnum } from '@enum/roleEnum';
//helpers
import { getDefaultPagnation, selectedTab } from '@helpers/utility';
import { exportUsersOptionsFields } from '@helpers/formFields/formFields';
//recoil
import { useRecoilValue } from 'recoil';
import { userAtom } from '@atoms/userAtom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';

let deleteMe = false;

export function useUsers() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { userId } = useParams();
  const navigate = useNavigate();

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [searchParams, setSearchParams] = useSearchParams({ pageIndex: '0', pageSize: '10' });
  const [pagination, setPagination] = useState<MRT_PaginationState>(
    getDefaultPagnation(searchParams)
  );
  const [activeTab, setActiveTab] = useState(selectedTab(location.pathname));
  const [popup, setPopup] = useState<FormModalPropsType<any> | null>(null);

  const loggedUser = useRecoilValue(userAtom);
  const setErrorPopup = useSetRecoilState(infoPopupAtom);
  const resetErrorPopup = useResetRecoilState(infoPopupAtom);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const onError = (err: ErrorsResponseType<{}>) => {
    setErrorPopup({
      title: err.message,
      onClose: () => resetErrorPopup(),
      type: 'error'
    });
  };

  const onDelete = (data: User) => {
    if (loggedUser) deleteMe = loggedUser.id === data.id;

    deleteUser(data.id);
  };

  const handleReset = () => {
    setPopup(null);
    refetchAll();

    if (userId) navigate(`/users`);
    if (deleteMe) navigate('/login');
  };

  const onDeleteClick = (user: User) => {
    setPopup({
      title: t(`global.delete_title`, { object: t(`delete.user`) }),
      subtitle:
        user.type === RoleEnum.BUSINESS
          ? t(`user.admin_delete_subtitle`)
          : t(`global.delete_subtitle`, { object: t(`delete.user`) }),
      open: true,
      fields: [],
      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        onDelete(user);
      }
    });
  };

  const { control, handleSubmit } = useForm<ExportUsersParamsType>({
    defaultValues: {
      username: false,
      countryNew: true,
      birthCountry: true,
      city: true,
      address: true,
      religion: false,
      phone: true,
      gender: true,
      dateOfBirth: true,
      employmentStatus: true,
      relationshipStatus: true,
      communityLife: false,
      jobSeeker: true,
      disability: true,
      birthPlace: true,
      createdAt: true,
      updatedAt: false,
      userCompanies: true,
      relation: 'celebrations'
    }
  });

  const onSuccessExport = (data: { data: BlobPart }) => {
    const downloadUrl = window.URL.createObjectURL(
      //@ts-ignore
      new File([data.data], 'w-media', { type: data.data.type })
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'w-media-users');
    link.click();
    link.remove();
    setPopup(null);
  };

  const { mutate: exportUsers } = useExportUsersData(onError, onSuccessExport);

  const onSubmitExport = (data: ExportUsersParamsType) => {
    exportUsers(data);
  };

  const handleDownloadData = () => {
    setPopup({
      title: t(`user.export.export_title`),
      subtitle: t(`user.export.export_subtitle`),
      open: true,
      control: control,
      fields: exportUsersOptionsFields(t, control),

      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.export'),
      variant: 'form',

      onSubmit: handleSubmit(onSubmitExport)
    });
  };

  const {
    data: users,
    isLoading,
    refetch: refetchAll
  } = useAllUsers(
    {
      page: pagination.pageIndex + 1,
      take: pagination.pageSize,
      search: columnFilters.find(el => el.id === 'firstName')?.value as string
    },
    onError
  );

  const { data: user, isLoading: isLoadingSingle } = useSingleUser(userId, onError);
  const { data: genres } = useUsersGenres({ id: userId, lang: i18n.language }, onError);
  const { data: rubrics } = useUsersRubrics({ id: userId, lang: i18n.language }, onError);
  const { data: interests } = useUsersInterests({ id: userId, lang: i18n.language }, onError);
  const { data: industries } = useUsersIndustries({ id: userId, lang: i18n.language }, onError);
  const { data: celebrations } = useUsersCelebrations({ id: userId, lang: i18n.language }, onError);
  const { mutate: deleteUser, isLoading: isLoadingDelete } = useDeleteUser(handleReset, onError);

  useEffect(() => {
    if (location.pathname === '/users')
      setSearchParams({
        pageIndex: pagination.pageIndex.toString(),
        pageSize: pagination.pageSize.toString()
      });
  }, [pagination]);

  return {
    users,
    t,
    onDelete,
    rowCount: users?.meta.itemCount,
    pagination,
    setPagination,
    isLoading,
    user,
    handleReset,
    isLoadingSingle,
    activeTab,
    handleChange,
    onDeleteClick,
    popup,
    setPopup,
    columnFilters,
    setColumnFilters,
    isLoadingDelete,
    genres,
    rubrics,
    interests,
    industries,
    celebrations,
    handleDownloadData
  };
}
