//components
import { Box, FormControl, IconButton, TextField, Tooltip, Typography } from '@mui/material';
//types
import {
  Control,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
  useWatch
} from 'react-hook-form';
//icons
import { AddRounded } from '@mui/icons-material';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';

type MultipleFieldsPropsType<T extends Object> = {
  setValue: UseFormSetValue<T>;
  clearErrors: UseFormClearErrors<T> | undefined;
  fieldKey: Path<T>;
  getValues: UseFormGetValues<T> | undefined;
  title: string;
  control: Control<T> | undefined;
  errors?: FieldErrors<T>;
};

const MultipleFields = <T extends Object>({
  errors,
  setValue,
  clearErrors,
  fieldKey,
  getValues,
  title,
  control
}: MultipleFieldsPropsType<T>) => {
  const tags = useWatch({ control: control, name: fieldKey });
  const { t } = useTranslation();

  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box display={'flex'}>
          <Typography>{title}</Typography>
          <Tooltip title={t('global.info.' + fieldKey)}>
            <InfoOutlinedIcon sx={{ color: 'GrayText' }} />
          </Tooltip>
        </Box>

        <IconButton
          onClick={() => {
            if (getValues) {
              let new_value = getValues(fieldKey);
              new_value.push('');

              setValue(fieldKey, new_value);
            }
          }}
        >
          <AddRounded color="primary" />
        </IconButton>
      </Box>
      {tags?.map((tag: string, i: number) => (
        <FormControl fullWidth key={i}>
          <Box display={'flex'} gap={2}>
            <IconButton
              onClick={() => {
                if (getValues) {
                  let new_value = getValues(fieldKey);
                  if (new_value.length > 1) {
                    new_value.splice(i, 1);
                    setValue(fieldKey, new_value);
                  }
                }
              }}
            >
              <RemoveRoundedIcon color="error" />
            </IconButton>

            <TextField
              label={title}
              value={tag}
              onChange={ev => {
                if (errors && errors[fieldKey as keyof FieldErrors<T>] && clearErrors) clearErrors();
                setValue(`${fieldKey}.${i}` as Path<T>, ev.target.value as PathValue<T, Path<T>>);
              }}
              error={errors && !!errors[fieldKey as keyof FieldErrors<T>]}
              helperText={errors && errors[fieldKey as keyof FieldErrors<T>]?.message?.toString()}
            />
          </Box>
        </FormControl>
      ))}
    </Box>
  );
};

export default MultipleFields;
