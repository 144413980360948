//react
import { Dispatch, SetStateAction } from 'react';
//mui
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_PaginationState,
  MRT_ColumnFiltersState
} from 'material-react-table';
import { Box, Card, IconButton, Tooltip } from '@mui/material';
//icons
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
//translate
import { MRT_Localization_SR_LATN_RS } from 'material-react-table/locales/sr-Latn-RS';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useTranslation } from 'react-i18next';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';

const getTableLanguage = () => {
  const lng = localStorage.getItem('i18nextLng');
  if (lng === 'en') return MRT_Localization_EN;
  if (lng === 'de') return MRT_Localization_DE;
  if (lng === 'fr') return MRT_Localization_FR;

  return MRT_Localization_SR_LATN_RS;
};

interface TableLayoutPropsType<T extends object> {
  data: T[];
  columns: MRT_ColumnDef<T>[];
  rowCount?: number;
  pagination?: MRT_PaginationState;
  setPagination?: Dispatch<SetStateAction<MRT_PaginationState>>;
  withCheckbox?: boolean;
  columnFilters?: MRT_ColumnFiltersState;
  setColumnFilters?: Dispatch<SetStateAction<MRT_ColumnFiltersState>>;
  handleDelete?: (object: T) => void;
  handleEdit?: (object: T) => void;
  isLoading?: boolean;
  globalFilter?: string;
  setGlobalFilter?: Dispatch<SetStateAction<string>>;
  globalSearchText?: string;
}

const TableLayout = <T extends object>({
  data,
  columns,
  pagination,
  setPagination,
  rowCount,
  withCheckbox = false,
  setColumnFilters,
  columnFilters,
  handleDelete,
  handleEdit,
  isLoading,
  globalFilter,
  setGlobalFilter,
  globalSearchText
}: TableLayoutPropsType<T>) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ my: 1 }}>
      <MaterialReactTable
        columns={columns as MRT_ColumnDef<{}>[]}
        data={data}
        localization={{
          ...getTableLanguage(),
          filterByColumn: t('global.search'),
          search: globalSearchText
        }}
        initialState={{
          showGlobalFilter: true,
          showColumnFilters: true,
          density: 'compact'
        }}
        rowCount={rowCount}
        enableTopToolbar={setGlobalFilter !== undefined}
        //top toolbar actions
        enableHiding={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableColumnActions={false} // tri tacke kod headera da se obrisu filteri
        enableSorting={false}
        //checkbox
        enableRowSelection={withCheckbox}
        //pagination
        muiPaginationProps={{
          rowsPerPageOptions: [10, 15, 20, 25],
          showFirstButton: false,
          showLastButton: false
        }}
        enablePagination={!!pagination && !!setPagination}
        manualPagination={!!pagination && !!setPagination}
        onPaginationChange={setPagination}
        //states
        state={{
          columnFilters: columnFilters ? columnFilters : [],
          pagination: pagination ? pagination : { pageSize: data.length, pageIndex: 0 },
          isLoading: isLoading,
          globalFilter: globalFilter
        }}
        //columns filters
        manualFiltering={!!(columnFilters && setColumnFilters)}
        enableColumnFilters={!!(columnFilters && setColumnFilters)}
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        //row actions
        enableRowActions={!!(handleEdit || handleDelete)}
        positionActionsColumn="last"
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '0.5rem' }}>
            <Box display="flex" flexDirection="row">
              {handleEdit && (
                <Tooltip arrow placement="top" title={t('global.edit')}>
                  <IconButton
                    onClick={() => {
                      handleEdit(row.original as T);
                    }}
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                </Tooltip>
              )}

              {handleDelete && (
                <Tooltip arrow placement="top" title={t('global.delete')}>
                  <IconButton
                    color="error"
                    onClick={() => {
                      handleDelete(row.original as T);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        )}
        //style
        muiSearchTextFieldProps={{ variant: 'outlined', size: 'small' }}
        muiTablePaperProps={{ elevation: 0, sx: { py: 2 } }}
        muiFilterTextFieldProps={{ variant: 'outlined', size: 'small' }}
        muiTableHeadCellProps={{
          sx: columns.find(c => c.header === '') ? { display: 'none' } : {}
        }}
        muiBottomToolbarProps={{ sx: !pagination ? { display: 'none' } : {} }}
        muiCircularProgressProps={{
          color: 'primary',
          size: 55
        }}
        muiSkeletonProps={{
          animation: 'pulse',
          height: 20
        }}
      />
    </Card>
  );
};

export default TableLayout;
