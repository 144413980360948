import { NavLink } from 'react-router-dom';
//hooks
import { useMemo } from 'react';
import { useUsers } from '@hooks/useUsers/useUsers';
//components
import { Box, Button, IconButton, Typography } from '@mui/material';
import TableLayout from '@components/TableLayout/TableLayout';
import FormModal from '@components/FormModal/FormModal';
//helpers
import dayjs from 'dayjs';
//type
import { MRT_ColumnDef } from 'material-react-table';
import { User } from '@interfaces/users/users';
//icons
import DeleteIcon from '@mui/icons-material/Delete';
import { FileDownload } from '@mui/icons-material';

const UsersPage = () => {
  const {
    users,
    t,
    pagination,
    setPagination,
    isLoading,
    onDeleteClick,
    popup,
    setPopup,
    columnFilters,
    setColumnFilters,
    isLoadingDelete,
    handleDownloadData
  } = useUsers();

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'firstName',
        header: t('global.name_and_surname'),
        Cell: ({ row }) => (
          <NavLink to={`single/${row.original.id}`}>
            {row.original.firstName
              ? (row.original.firstName ?? '') + ' ' + (row.original.lastName ?? '')
              : row.original.email}
          </NavLink>
        )
      },
      {
        accessorKey: 'type',
        header: t('global.role'),
        Cell: ({ row }) => (row.original.type ? row.original.type : 'regular'),
        filterVariant: 'select',
        filterSelectOptions: ['admin', 'busness', 'regular'],
        enableColumnFilter: false
      },
      {
        accessorKey: 'emailVerifiedAt',
        header: t('user.email_verified'),
        Cell: ({ cell }) =>
          cell.getValue<string>() ? dayjs(cell.getValue<string>()).format('DD.MM.YYYY.') : '',
        enableColumnFilter: false
      },
      {
        accessorKey: 'createdAt',
        header: t('user.created_at'),
        Cell: ({ cell }) => dayjs(cell.getValue<string>()).format('DD.MM.YYYY.'),
        enableColumnFilter: false
      },
      {
        accessorKey: 'id',
        header: t('global.actions'),
        enableColumnFilter: false,
        Cell: ({ row }) =>
          row.original.type !== 'admin' && (
            <IconButton onClick={() => onDeleteClick(row.original)} color="error">
              <DeleteIcon />
            </IconButton>
          )
      }
    ],
    [users, t]
  );

  return (
    <Box className="container">
      {popup && (
        <FormModal
          title={popup.title}
          subtitle={popup.subtitle}
          open={true}
          control={popup.control}
          fields={popup.fields}
          onCancel={() => {
            popup.onCancel();
            setPopup(null);
          }}
          onSubmit={popup.onSubmit}
          setValue={popup.setValue}
          buttonText={popup.buttonText}
          variant={popup.variant}
          setError={popup.setError}
          isLoading={isLoadingDelete}
        />
      )}
      <Box mt={1}>
        <Typography fontWeight={'bold'} variant="h4">
          {t('user.page_title')}
        </Typography>
        <Typography variant="subtitle1" color={'text.secondary'}>
          {t('user.page_subtitle')}
        </Typography>
      </Box>
      <Box mt={2}>
        <Box display={'flex'} width={'100%'} justifyContent={'flex-end'}>
          <Button onClick={() => handleDownloadData()} variant="outlined" sx={{ mr: 2 }}>
            {t('global.export')}
            <FileDownload />
          </Button>
        </Box>
        <TableLayout
          columns={columns}
          data={users ? users.data : []}
          pagination={pagination}
          setPagination={setPagination}
          rowCount={users ? users.meta.itemCount : 0}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default UsersPage;
