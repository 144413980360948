import { NavLink, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
//components
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import TableLayout from '@components/TableLayout/TableLayout';
import FormModal from '@components/FormModal/FormModal';
import Loading from '@components/Loading/Loading';
//types
import { MRT_ColumnDef } from 'material-react-table';
import { VoucherType } from '@interfaces/vouchers/vouchers';
//hooks
import { useVoucher } from '@hooks/useVoucher/useVoucher';
import { useTranslation } from 'react-i18next';
//icons
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteIcon from '@mui/icons-material/Delete';
//helpers
import { getVoucherTypographyColor, dayjs, getTranslationValue } from '@helpers/utility';
import { StatusEnum } from '@enum/progressEnum';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';

const VouchersPage = () => {
  const {
    vouchers,
    onDeleteVoucherClick,
    popup,
    pagination,
    setPagination,
    rowCount,
    columnFilters,
    setColumnFilters,
    isLoadingAll,
    interests,
    isLoadingDelete
  } = useVoucher();

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo<MRT_ColumnDef<VoucherType>[]>(
    () => [
      {
        accessorKey: 'title',
        header: t('global.title'),
        Cell: ({ row, cell }) => <NavLink to={row.original.id}>{cell.getValue<string>()}</NavLink>
      },
      {
        accessorKey: 'status',
        header: t('global.status'),
        Cell: ({ cell }) => (
          <Typography color={getVoucherTypographyColor(cell.getValue<string>())}>
            {t(`status.${cell.getValue<string>()}`)}
          </Typography>
        ),
        filterVariant: 'select',
        filterSelectOptions: [
          { text: t('status.' + StatusEnum.draft), value: StatusEnum.draft },
          { text: t('status.' + StatusEnum.pending), value: StatusEnum.pending },
          { text: t('status.' + StatusEnum.active), value: StatusEnum.active },
          { text: t('status.' + StatusEnum.finished), value: StatusEnum.finished },
          { text: t('status.' + StatusEnum.declined), value: StatusEnum.declined }
        ]
      },
      {
        accessorKey: 'interests',
        header: t('global.categories'),
        Cell: ({ row }) => (
          <Tooltip title={row.original.interests?.map(el => el.name).join(', ')}>
            <span>
              {row.original.interests
                ?.map(el => el.name)
                .join(', ')
                .slice(0, 30)}
            </span>
          </Tooltip>
        ),
        filterVariant: 'select',
        filterSelectOptions: interests.map(r => {
          return {
            text: getTranslationValue(
              r.translations,
              'name',
              i18n.language as SupportedLanguagesEnum
            ),
            value: r.id
          };
        })
      },
      {
        accessorKey: 'couponLimit',
        header: t('voucher.coupon_limit'),
        enableColumnFilter: false
      },
      {
        accessorKey: 'validUntil',
        header: t('voucher.valid_until'),
        Cell: ({ cell }) =>
          cell.getValue<string>() ? dayjs(cell.getValue<string>()).format('DD.MM.YYYY.') : '',
        enableColumnFilter: false
      },
      {
        accessorKey: 'id',
        header: t('global.actions'),
        Cell: ({ row }) =>
          row.original.status !== 'active' && (
            <IconButton onClick={() => onDeleteVoucherClick(row.original.id)} color="error">
              <DeleteIcon />
            </IconButton>
          ),
        enableColumnFilter: false
      }
    ],
    [vouchers, t]
  );

  return (
    <Box className="container">
      {popup && (
        <FormModal
          title={popup.title}
          subtitle={popup.subtitle}
          open={true}
          control={popup.control}
          fields={popup.fields}
          onCancel={popup.onCancel}
          onSubmit={popup.onSubmit}
          setValue={popup.setValue}
          buttonText={popup.buttonText}
          variant={popup.variant}
          setError={popup.setError}
          isLoading={isLoadingDelete}
        />
      )}
      <Box mt={1}>
        <Typography fontWeight={'bold'} variant="h4">
          {t('global.vouchers')}
        </Typography>
        <Typography variant="subtitle1" color={'text.secondary'}>
          {t('voucher.page_subtitle')}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} gap={2} mb={2}>
        <Button
          variant="outlined"
          onClick={() => {
            navigate('create');
          }}
        >
          {t('voucher.add_voucher')} <AddRoundedIcon />
        </Button>
      </Box>
      <Box>
        <TableLayout
          columns={columns}
          data={vouchers}
          rowCount={rowCount}
          pagination={pagination}
          setPagination={setPagination}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          isLoading={isLoadingAll}
        />
      </Box>
    </Box>
  );
};

export default VouchersPage;
