//react-query
import { CouponStatusEnum, StatusEnum } from '@enum/progressEnum';
import { ErrorsResponseType } from '@interfaces/response';
import {
  AllCouponsResponseType,
  AllVouchersResponseType,
  CurrencyType,
  VoucherType
} from '@interfaces/vouchers/vouchers';
import { getAllCurrencies } from '@services/currency/currency';
import {
  getAllActiveVouchers,
  getAllCouponsForVoucher,
  getAllVoucher,
  getSingleVoucher
} from '@services/vouchers/vouchers';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult
} from '@tanstack/react-query';

export const useAllVouchers = (
  onError: (err: ErrorsResponseType<{}>) => void,
  pagination?: {
    page: number;
    take: number;
    status?: StatusEnum;
    search?: string;
    interestId?: string;
    lang?: string;
  }
): UseQueryResult<AllVouchersResponseType, ErrorsResponseType<{}>> =>
  useQuery(['vouchers', pagination], () => getAllVoucher(pagination), {
    onError: err => onError(err),
    select: res => res.data
  });

export const useInfinityVouchers = (
  params: { page: number; take: number; status?: StatusEnum; search?: string; category?: string },
  onError: (err: ErrorsResponseType<{}>) => void,
  enabled: boolean
): UseInfiniteQueryResult<{ data: AllVouchersResponseType }, ErrorsResponseType<{}>> =>
  useInfiniteQuery(
    ['infinity-vouchers', params],
    a =>
      getAllVoucher({
        ...params,
        page: a.pageParam
      }),
    {
      getNextPageParam: lastPage =>
        lastPage.data.meta.hasNextPage ? lastPage.data.meta.page + 1 : undefined,
      onError: err => onError(err),
      enabled: enabled
    }
  );

export const useSingleVoucher = (
  id: string | undefined,
  onError: (err: ErrorsResponseType<{}>) => void,
  lang?: string,
  onSuccess?: (data: VoucherType) => void
): UseQueryResult<VoucherType | undefined, ErrorsResponseType<{}>> =>
  useQuery(['voucher', id, lang], () => (id ? getSingleVoucher(id, lang) : null), {
    select: res => res?.data,
    onError: err => onError(err),
    enabled: !!id,
    onSuccess: res => (res && onSuccess ? onSuccess(res) : null)
  });

export const useAllCurrencies = (
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<CurrencyType[], ErrorsResponseType<{}>> =>
  useQuery(['currencies'], () => getAllCurrencies(), {
    onError: err => onError(err),
    select: res => res.data
  });

export const useAllActiveVouchers = (
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<VoucherType[], ErrorsResponseType<{}>> =>
  useQuery(['active-vouchers'], () => getAllActiveVouchers(), {
    onError: err => onError(err),
    select: res => res.data
  });

export const useAllCouponsForVoucher = (
  id: string | undefined,
  onError: (err: ErrorsResponseType<{}>) => void,
  pagination?: {
    page: number;
    take: number;
    status?: CouponStatusEnum;
  }
): UseQueryResult<AllCouponsResponseType | undefined, ErrorsResponseType<{}>> =>
  useQuery(
    ['coupons', id, pagination],
    () => (id ? getAllCouponsForVoucher(id, pagination) : null),
    {
      onError: err => onError(err),
      select: res => res?.data,
      enabled: !!id
    }
  );
