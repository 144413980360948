import {
  AllCompaniesResponseType,
  CompanyType,
  CreateCompanyType,
  UpdateCompanyRequestType
} from '@interfaces/company/company';
import { MetaType } from '@interfaces/response';
import axiosInstance from '@services/config';

export const getAllCompanies = (params?: {
  page: number;
  take: number;
  search?: string;
  industryId?: string;
  lang?: string;
}): Promise<{ data: AllCompaniesResponseType }> =>
  axiosInstance.get('v1/companies/paged', { params: { ...params, order: 'DESC' } });

export const getSingleCompany = (id: string, lang?: string): Promise<{ data: CompanyType }> =>
  axiosInstance.get('v1/companies/' + id, { params: { lang } });

export const getCompaniesByUser = (): Promise<{ data: CompanyType[] }> =>
  axiosInstance.get('v1/companies/me');

export const createCompany = (data: CreateCompanyType): Promise<{ data: CompanyType }> =>
  axiosInstance.post('v1/companies', data);

export const updateCompany = (data: UpdateCompanyRequestType): Promise<{ data: CompanyType }> =>
  axiosInstance.patch('v1/companies/' + data.id, data.data);

export const deleteCompany = (id: string): Promise<{}> =>
  axiosInstance.delete('v1/companies/' + id);

export const uploadImage = (
  companyId: string,
  type: 'image' | 'coverImage' | 'heroImage',
  data: FormData
): Promise<{ data: { image?: string; coverImage?: string } }> =>
  axiosInstance.patch(`v1/companies/${companyId}/images?type=` + type, data);

export const deleteImage = (
  companyId: string,
  type: 'image' | 'coverImage' | 'heroImage'
): Promise<{}> => axiosInstance.delete(`v1/companies/${companyId}/images?type=` + type);
