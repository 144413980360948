//hooks
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useAllCouponsForVoucher } from '@api/queries/vouchers/vouchers';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
//components
import Loading from '@components/Loading/Loading';
import TableLayout from '@components/TableLayout/TableLayout';
import { Box } from '@mui/material';
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';
//type
import { ErrorsResponseType } from '@interfaces/response';
import { CouponType } from '@interfaces/vouchers/vouchers';

const VoucherCouponsPage = () => {
  const { voucherId } = useParams();

  const setErrorPopup = useSetRecoilState(infoPopupAtom);
  const resetErrorPopup = useResetRecoilState(infoPopupAtom);

  const [pagination, setPagination] = useState<MRT_PaginationState>({ pageIndex: 0, pageSize: 10 });

  const { t } = useTranslation();

  const onError = (err: ErrorsResponseType<{}>) => {
    setErrorPopup({
      title: err.message,
      onClose: () => resetErrorPopup(),
      type: 'error'
    });
  };

  const { data: coupons, isLoading } = useAllCouponsForVoucher(voucherId, onError);

  const columns = useMemo<MRT_ColumnDef<CouponType>[]>(
    () => [
      {
        accessorKey: 'user.firstName',
        header: t('global.user'),
        enableColumnFilter: false,
        Cell: ({ row }) =>
          (row.original.user.firstName ?? '') + ' ' + (row.original.user.lastName ?? '')
      },
      {
        accessorKey: 'user.email',
        header: t('global.email'),
        enableColumnFilter: false
      },
      {
        accessorKey: 'status',
        header: t('global.status'),
        enableColumnFilter: false,
        Cell: ({ row }) => t(`status.${row.original.status}`)
      }
    ],
    [coupons, t]
  );

  if (isLoading) return <Loading />;
  return (
    <Box>
      <TableLayout
        columns={columns}
        data={coupons ? coupons.data : []}
        pagination={pagination}
        setPagination={setPagination}
        rowCount={coupons ? coupons.meta.itemCount : 0}
      />
    </Box>
  );
};

export default VoucherCouponsPage;
