//hook
import { useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { FormModalPropsType } from '@components/FormModal/FormModal';
//components
import AutocompleteInfiniteScroll from '@components/AutocompleteInfiniteScroll/AutocompleteInfiniteScroll';
//types
import { CreateSliderItemType, SliderItemPreviewType } from '@interfaces/sliders/sliders';
import { MappedFieldsType } from '@interfaces/modalForm';
//enum
import { InputTypeEnum } from '@enum/formModal';
import { JobStatusEnum } from '@enum/companyEnum';
import { StatusEnum } from '@enum/progressEnum';
import { SliderItemTypeEnum } from '@enum/sliderEnum';
//api
import { useCreateSliderItem, useDeleteSliderItem } from '@api/mutations/sliders/sliders';
import { useInfinityVouchers } from '@api/queries/vouchers/vouchers';
import { useInfinityJobs } from '@api/queries/jobs/jobs';
import { useAllNewsInfinity } from '@api/queries/news/news';
//recoil
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import SliderItemCard from '@components/SliderItemCard/SliderItemCard';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';
import { useSingleSlider } from '@api/queries/sliders/sliders';
import { useAllCompaniesInfinity } from '@api/queries/company/company';

export function useSliderItem() {
  const { t, i18n } = useTranslation();
  const { sliderId } = useParams();

  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const [popup, setPopup] = useState<FormModalPropsType<CreateSliderItemType> | null>(null);
  const [items, setItems] = useState<SliderItemPreviewType[]>([]);
  const [selected, setSelected] = useState<SliderItemPreviewType>();
  const [index, setIndex] = useState(0);

  const [preview, setPreview] = useState<SliderItemPreviewType | null>(null);
  const [fields, setFields] = useState<MappedFieldsType[]>([]);

  const [search, setSearch] = useState('');

  const { control, handleSubmit, setValue, setError, reset } = useForm<CreateSliderItemType>();
  const selectedType = useWatch({
    control,
    name: 'entityType'
  });

  const selectedEntity = useWatch({
    control,
    name: 'entityId'
  });

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const onCreateError = (err: ErrorType<CreateSliderItemType>) => {
    if (err.errors)
      for (const key in err.errors) {
        setError(key as keyof CreateSliderItemType, {
          type: 'custom',
          message: err.errors[key as keyof CreateSliderItemType]?.join(', ')
        });
      }
    else {
      setInfoPopup({
        title: err.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
      setError('root', {
        type: 'custom',
        message: err.message
      });
    }
  };

  const {
    data: companiesInfinity,
    fetchNextPage: fetchNextPageCompanies,
    hasNextPage: hasNextPageCompanies,
    isLoading: isLoadingCompanies,
    isFetching: isFetchingCompanies
  } = useAllCompaniesInfinity(i18n.language, onError, selectedType === 'company', search);

  const {
    data: allJobsInfinity,
    fetchNextPage: fetchNextPageJobs,
    hasNextPage: hasNextPageJobs,
    isLoading: isLoadingJobs,
    isFetching: isFetchingJobs
  } = useInfinityJobs(
    {
      status: JobStatusEnum.ALLOWED,
      take: 10,
      search
    },
    onError,
    selectedType === 'job'
  );

  const {
    data: allNewsInfinity,
    fetchNextPage: fetchNextPageNews,
    hasNextPage: hasNextPageNews,
    isLoading: isLoadingNews,
    isFetching: isFetchingNews
  } = useAllNewsInfinity(onError, selectedType === 'news', { search });

  const {
    data: allVouchersInfinity,
    fetchNextPage: fetchNextPageVouchers,
    hasNextPage: hasNextPageVouchers,
    isLoading: isLoadingVouchers,
    isFetching: isFetchingVouchers
  } = useInfinityVouchers(
    {
      status: StatusEnum.active,
      take: 10,
      page: 0,
      search
    },
    onError,
    selectedType === 'voucher'
  );

  const {
    mutate: createSliderItem,
    isSuccess: isSuccessCreate,
    isLoading: isLoadingCreate
  } = useCreateSliderItem(onCreateError);

  const { mutate: deleteSliderItem, isLoading: isLoadingDelete } = useDeleteSliderItem(() => {
    refetchSlider();
    setPopup(null);
  }, onError);

  const {
    data: singleSlider,
    isLoading: isLoadingSlider,
    refetch: refetchSlider
  } = useSingleSlider(sliderId, onError);

  const onSubmit = (data: CreateSliderItemType) => {
    if (sliderId) {
      data.sliderId = sliderId;
      createSliderItem(data);
    }
  };

  const onCancel = () => {
    setPopup(null);
    reset({});
    setPreview(null);
  };

  const getInfinityEntityList = () => {
    if (selectedType === 'voucher') {
      return allVouchersInfinity?.pages
        .flatMap(item => item.data.data)
        ?.map(v => {
          return { id: v.id, name: v.title };
        });
    } else if (selectedType === 'news') {
      return allNewsInfinity?.pages
        .flatMap(item => item.data.data)
        .map(v => {
          return { id: v.id, name: v.title ? v.title : '' };
        });
    } else if (selectedType === 'job') {
      return allJobsInfinity?.pages
        .flatMap(item => item.data.data)
        .map(v => {
          return { id: v.id, name: v.title ? v.title : '' };
        });
    } else if (selectedType === 'company') {
      return companiesInfinity?.pages
        .flatMap(item => item.data.data)
        .map(v => {
          return { id: v.id, name: v.title ? v.title : '' };
        });
    }
    return [];
  };

  const getInfinityParams = () => {
    if (selectedType === 'voucher') {
      return {
        isLoading: isLoadingVouchers,
        isFetching: isFetchingVouchers,
        hasNextPage: hasNextPageVouchers,
        fetchNextPage: fetchNextPageVouchers
      };
    } else if (selectedType === 'news') {
      return {
        isLoading: isLoadingNews,
        isFetching: isFetchingNews,
        hasNextPage: hasNextPageNews,
        fetchNextPage: fetchNextPageNews
      };
    } else if (selectedType === 'job') {
      return {
        isLoading: isLoadingJobs,
        isFetching: isFetchingJobs,
        hasNextPage: hasNextPageJobs,
        fetchNextPage: fetchNextPageJobs
      };
    }
    return {
      isLoading: isLoadingCompanies,
      isFetching: isFetchingCompanies,
      hasNextPage: hasNextPageCompanies,
      fetchNextPage: fetchNextPageCompanies
    };
  };

  useEffect(() => {
    if (items.length > 0) {
      if (index < 0) {
        setIndex(items.length - 1);
      }
      if (index < items.length) {
        if (selected !== items[index]) {
          setSelected(items[index]);
        }
      } else {
        setIndex(0);
      }
    }
  }, [index, items]);

  useEffect(() => {
    if (singleSlider) {
      const sliderItems = singleSlider?.sliderItems.map(sliderItem => {
        let pom: SliderItemPreviewType = {
          heroImage: null,
          type: 'type',
          title: null,
          shortDescription: null,
          id: ''
        };
        if (sliderItem) {
          pom = {
            heroImage: sliderItem[sliderItem.type]?.heroImage,
            type: sliderItem.type,
            title: sliderItem[sliderItem.type]?.title,
            shortDescription: sliderItem[sliderItem.type]?.shortDescription,
            id: sliderItem.id
          };
        }
        return pom;
      });

      if (sliderItems) setItems(sliderItems);
    }
  }, [singleSlider]);

  useEffect(() => {
    if (selectedType === 'voucher') {
      const tmp = allVouchersInfinity?.pages
        .flatMap(item => item.data.data)
        ?.find(v => v.id === selectedEntity);
      if (tmp)
        setPreview({
          heroImage: tmp.heroImage,
          type: 'voucher',
          title: tmp.title,
          shortDescription: tmp.shortDescription ? tmp.shortDescription : null,
          id: tmp.id,
          tags: tmp.tags
        });
    } else if (selectedType === 'news') {
      const tmp = allNewsInfinity?.pages
        .flatMap(item => item.data.data)
        .find(v => v.id === selectedEntity);
      if (tmp)
        setPreview({
          heroImage: tmp.heroImage,
          type: 'news',
          title: tmp.title,
          shortDescription: tmp.shortDescription ? tmp.shortDescription : null,
          id: tmp.id,
          tags: tmp.tags
        });
    } else if (selectedType === 'job') {
      const tmp = allJobsInfinity?.pages
        .flatMap(item => item.data.data)
        .find(v => v.id === selectedEntity);
      if (tmp)
        setPreview({
          heroImage: tmp.heroImage,
          type: 'job',
          title: tmp.title,
          shortDescription: tmp.shortDescription,
          id: tmp.id,
          tags: tmp.tags
        });
    } else if (selectedType === 'company') {
      const tmp = companiesInfinity?.pages
        .flatMap(item => item.data.data)
        .find(v => v.id === selectedEntity);
      if (tmp)
        setPreview({
          heroImage: tmp.heroImage,
          type: 'job',
          title: tmp.title,
          shortDescription: tmp.shortDescription,
          id: tmp.id,
          tags: tmp.tags
        });
    }
  }, [selectedEntity]);

  useEffect(() => {
    if (selectedType) {
      setPreview(null);
      setValue('entityId', '');
    }
  }, [selectedType]);

  useEffect(() => {
    setFields([
      {
        key: 'entityType',
        type: InputTypeEnum.select,
        label: t('global.type'),
        select_options: Object.values(SliderItemTypeEnum),
        required: true
      },
      {
        key: 'entityId',
        type: InputTypeEnum.custom,
        label: t('global.title'),
        element: (
          <AutocompleteInfiniteScroll
            name="entityId"
            control={control}
            required={true}
            options={getInfinityEntityList()}
            label={t('global.title')}
            infinityOptions={getInfinityParams()}
            setValue={setValue}
            key={selectedType}
            setSearch={setSearch}
          />
        )
      },
      {
        key: 'sliderId',
        type: InputTypeEnum.custom,
        label: t('global.title'),
        element: (
          <SliderItemCard
            heroImage={preview?.heroImage}
            title={preview?.title}
            shortDescription={preview?.shortDescription}
            type={preview?.type}
            tags={preview?.tags}
          />
        )
      }
    ]);
  }, [
    selectedType,
    preview,
    allNewsInfinity,
    allVouchersInfinity,
    allJobsInfinity,
    companiesInfinity
  ]);

  useEffect(() => {
    if (isSuccessCreate) {
      onCancel();
      refetchSlider();
      setInfoPopup({
        title: t('global.success_create'),
        onClose: () => resetInfoPopup(),
        type: 'success'
      });
    }
  }, [isSuccessCreate]);

  const onAddClick = () => {
    setPopup({
      title: t('slider.add_slider_item'),
      open: true,
      fields: [],
      control: control,
      onCancel: onCancel,
      onSubmit: handleSubmit(onSubmit),
      setValue: setValue,
      buttonText: t('global.add'),
      variant: 'form',
      setError: setError
    });
  };

  const onDeleteClick = (id: string) => {
    setPopup({
      title: t(`global.delete_title`, { object: t(`delete.slider`) }),
      subtitle: t(`global.delete_subtitle`, { object: t(`delete.slider`) }),
      open: true,
      fields: [],

      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        deleteSliderItem(id);
      }
    });
  };

  return {
    onAddClick,
    onDeleteClick,
    items,
    popup,
    fields,
    selected,
    setIndex,
    index,
    singleSlider,
    isLoadingSlider,
    isSubmitting: isLoadingDelete || isLoadingCreate
  };
}
