import { useMemo } from 'react';
//components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { Parser } from '@alkhipce/editorjs-react';
import TableLayout from '@components/TableLayout/TableLayout';
import Loading from '@components/Loading/Loading';
import FormModal from '@components/FormModal/FormModal';
//hooks
import { useTranslation } from 'react-i18next';
import { useVoucher } from '@hooks/useVoucher/useVoucher';
import { Navigate } from 'react-router-dom';
//type
import { MRT_ColumnDef } from 'material-react-table';
import { VoucherType } from '@interfaces/vouchers/vouchers';
import { IParser } from '@alkhipce/editorjs-react/dist/types/ParserData';
//helpers
import { getVoucherKeysName } from '@helpers/profileFields/profileFields';
import { getVoucherTypographyColor, dayjs } from '@helpers/utility';
//recoil
import { useRecoilValue } from 'recoil';
import { userAtom } from '@atoms/userAtom';
//enum
import { RoleEnum } from '@enum/roleEnum';
import { StatusEnum } from '@enum/progressEnum';

const SingleVoucherPage = () => {
  const {
    singleVoucher,
    isLoadingSingle,
    handleApprove,
    handleDecline,
    popup,
    isLoadingDelete,
    isLoadingStatus
  } = useVoucher();

  const { t } = useTranslation();

  const user = useRecoilValue(userAtom);

  const columns = useMemo<MRT_ColumnDef<{ label: string; key: keyof VoucherType }>[]>(
    () => [
      {
        accessorKey: 'label',
        header: '',
        enableColumnFilter: false,
        Cell: ({ cell }) => <Typography color={'primary'}>{cell.getValue<string>()}</Typography>
      },
      {
        accessorKey: 'key',
        header: '',
        enableColumnFilter: false,
        Cell: ({ row }) => {
          if (row.original.key === 'value')
            return (
              <Typography>
                {singleVoucher?.value?.value +
                  ' ' +
                  (singleVoucher?.value?.currency ? singleVoucher?.value?.currency?.symbol : '')}
              </Typography>
            );
          if (row.original.key === 'validUntil' && singleVoucher?.validUntil) {
            return <Typography>{dayjs(singleVoucher.validUntil).format('DD.MM.YYYY.')}</Typography>;
          }
          if (row.original.key === 'createdAt' && singleVoucher?.createdAt) {
            return <Typography>{dayjs(singleVoucher.createdAt).format('DD.MM.YYYY.')}</Typography>;
          }
          if (row.original.key === 'tags' || row.original.key === 'hashTags') {
            return <Typography>{singleVoucher?.[row.original.key]?.join(', ')}</Typography>;
          }

          if (row.original.key === 'shortDescription') {
            return (
              <Tooltip title={singleVoucher?.shortDescription}>
                <Typography>{singleVoucher?.shortDescription?.slice(0, 100)}</Typography>
              </Tooltip>
            );
          }

          if (row.original.key === 'status' && singleVoucher) {
            return (
              <Typography color={getVoucherTypographyColor(singleVoucher.status)}>
                {t(`status.${singleVoucher?.status}`)}
              </Typography>
            );
          }

          if (row.original.key === 'type' && singleVoucher) {
            return <Typography>{t(`voucher.discount.${singleVoucher?.type}`)}</Typography>;
          }

          if (row.original.key === 'targetGroup' && singleVoucher) {
            return (
              <Typography>
                {t(`global.${singleVoucher?.targetGroup}`, singleVoucher?.targetGroup)}
              </Typography>
            );
          }

          if (row.original.key === 'interests' && singleVoucher) {
            return <Typography>{singleVoucher.interests.map(el => el.name).join(', ')}</Typography>;
          }

          return <Typography>{singleVoucher?.[row.original.key]?.toString()}</Typography>;
        }
      }
    ],
    [singleVoucher, t]
  );

  if (isLoadingSingle) return <Loading />;
  if (!singleVoucher) return <Navigate to={'/error-page'} replace />;
  return (
    <Box>
      {popup && (
        <FormModal
          title={popup.title}
          subtitle={popup.subtitle}
          open={true}
          control={popup.control}
          fields={popup.fields}
          onCancel={popup.onCancel}
          onSubmit={popup.onSubmit}
          setValue={popup.setValue}
          buttonText={popup.buttonText}
          variant={popup.variant}
          setError={popup.setError}
          isLoading={isLoadingStatus || isLoadingDelete}
        />
      )}
      <Accordion defaultExpanded={true}>
        <AccordionSummary>
          <Typography variant="h5" fontWeight={'bold'} color={'primary'} my={1}>
            {t('global.basic_info')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {user?.type === RoleEnum.ADMIN &&
            (singleVoucher.status === StatusEnum.pending ||
              singleVoucher.status === StatusEnum.draft) && (
              <Box display={'flex'} justifyContent={'flex-end'} mt={-6} mb={1}>
                <Button
                  variant="outlined"
                  size="small"
                  color="success"
                  onClick={() => {
                    handleApprove();
                  }}
                  sx={{ mr: 1 }}
                >
                  {t('global.approve')}
                </Button>

                {/* <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  onClick={() => {
                    handleDecline();
                  }}
                >
                  {t('global.decline')}
                </Button> */}
              </Box>
            )}
          <TableLayout data={getVoucherKeysName(t)} columns={columns} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5" fontWeight={'bold'} color={'primary'} my={1}>
            {t('voucher.overview')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Parser data={singleVoucher.data as IParser} />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SingleVoucherPage;

// #62c767 ACTIVE
// #0c6d14 FINISHED
