//type
import { ErrorsResponseType } from '@interfaces/response';
import { InterestsType } from '@interfaces/interests/interests';
//api
import {
  getGenres,
  getIndustries,
  getInterests,
  getRubrics,
  getSingleGenre,
  getSingleIndustry,
  getSingleInterest,
  getSingleRubric
} from '@services/interests/interests';
//react-query
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const useAllRubrics = (
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<InterestsType[], ErrorsResponseType<{}>> =>
  useQuery(['rubrics'], () => getRubrics(), {
    onError: err => onError(err),
    select: res => res.data
  });

export const useAllIndustries = (
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<InterestsType[], ErrorsResponseType<{}>> =>
  useQuery(['industries'], () => getIndustries(), {
    onError: err => onError(err),
    select: res => res.data
  });

export const useAllGenres = (
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<InterestsType[], ErrorsResponseType<{}>> =>
  useQuery(['genres'], () => getGenres(), {
    onError: err => onError(err),
    select: res => res.data
  });

export const useSingleRubric = (
  id: string | undefined,
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<InterestsType | undefined, ErrorsResponseType<{}>> =>
  useQuery(['rubrics', id], () => (id ? getSingleRubric(id) : null), {
    onError: err => onError(err),
    select: res => res?.data,
    enabled: !!id
  });

export const useSingleGenre = (
  id: string | undefined,
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<InterestsType | undefined, ErrorsResponseType<{}>> =>
  useQuery(['genre', id], () => (id ? getSingleGenre(id) : null), {
    onError: err => onError(err),
    select: res => res?.data,
    enabled: !!id
  });

export const useSingleIndustry = (
  id: string | undefined,
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<InterestsType | undefined, ErrorsResponseType<{}>> =>
  useQuery(['industry', id], () => (id ? getSingleIndustry(id) : null), {
    onError: err => onError(err),
    select: res => res?.data,
    enabled: !!id
  });

export const useAllInterests = (
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<InterestsType[], ErrorsResponseType<{}>> =>
  useQuery(['interests'], () => getInterests(), {
    onError: err => onError(err),
    select: res => res.data
  });

export const useSingleInterest = (
  id: string | undefined,
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<InterestsType | undefined, ErrorsResponseType<{}>> =>
  useQuery(['interests', id], () => (id ? getSingleInterest(id) : null), {
    onError: err => onError(err),
    select: res => res?.data,
    enabled: !!id
  });
