import { MutableRefObject, useEffect } from 'react';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import Underline from '@editorjs/underline';
import Quote from '@editorjs/quote';
import Embed from '@editorjs/embed';
import Marker from '@editorjs/marker';
import InlineCode from '@editorjs/inline-code';
import ColorPlugin from 'editorjs-text-color-plugin';
import { Box } from '@mui/material';

type EditorPropsType = {
  ejInstance: MutableRefObject<EditorJS | undefined>;
  setEditorData: (data: OutputData) => void;
  editorData: OutputData;
  uploadImage?: Function;
};

const Editor = ({ ejInstance, setEditorData, editorData, uploadImage }: EditorPropsType) => {
  const initEditor = () => {
    const editor = new EditorJS({
      holder: 'editorjs',
      onReady: () => {
        ejInstance.current = editor;
      },
      autofocus: true,
      data: editorData,
      onChange: async () => {
        let content = await editor.saver.save();
        setEditorData(content);
      },
      tools: {
        header: Header,
        underline: Underline,

        embed: {
          class: Embed,
          config: {
            services: {
              youtube: true
            }
          }
        },

        Color: {
          class: ColorPlugin,
          config: {
            colorCollections: ['#FF0000', '#0000FF', '#00FF00', '#FFF'],
            defaultColor: '#FF1300',
            type: 'text',
            customPicker: true
          }
        },

        image: {
          class: ImageTool,
          config: {
            uploader: {
              uploadByFile: uploadImage
              
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    if (ejInstance.current !== undefined) {
      initEditor();
      return () => {
        ejInstance.current = undefined;
      };
    }
  }, []);

  return (
    <Box>
      <Box
        id="editorjs"
        ref={ejInstance}
        style={{ border: '1px solid lightGray', borderRadius: '.25rem', padding: '10px' }}
      />
    </Box>
  );
};

export default Editor;
