import { MutableRefObject, useState } from 'react';
//components
import UploadImage from '@components/UploadImage/UploadImage';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import Editor from '@components/EditorJS/Editor';
import MultipleFields from '@components/MultipleFields/MultipleFields';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import { Parser } from '@alkhipce/editorjs-react';
import { IParser } from '@alkhipce/editorjs-react/dist/types/ParserData';
//icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//react form
import {
  Control,
  Controller,
  Path,
  PathValue,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
  useWatch
} from 'react-hook-form';
//types
import { MappedFieldsType } from '@interfaces/modalForm';
import { InputTypeEnum } from '@enum/formModal';
//hooks
import { useTranslation } from 'react-i18next';
//helpers
import AutocompleteInputField from '@components/Autocomplete/AutocomleteInputField';
import { AutpocompleteTypeEnum } from '@enum/autocompleteEnum';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DatePickerCustom from '@components/DatePicker/DatePicker';
import dayjs from 'dayjs';

export type FormInputFieldPropsType<T extends {}> = {
  option: MappedFieldsType;
  control?: Control<T>;
  setValue?: UseFormSetValue<T>;
  setError?: UseFormSetError<T>;
  keyPrefix?: string;
  required?: boolean;
  clearErrors?: UseFormClearErrors<T>;
  getValues?: UseFormGetValues<T>;
  ejInstance?: MutableRefObject<EditorJS | undefined>;
};

const FormInputField = <T extends {}>({
  option,
  control,
  setValue,
  setError,
  required,
  clearErrors,
  getValues,
  ejInstance,
  keyPrefix = ''
}: FormInputFieldPropsType<T>) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const title = useWatch({ name: (keyPrefix + 'title') as Path<T>, control: control });

  if (option.type === InputTypeEnum.custom) {
    return <div>{option.element}</div>;
  }
  return (
    <Controller
      name={`${keyPrefix}${option.key.toString()}` as Path<T>}
      control={control}
      defaultValue={
        (option.multiselect || option.type === InputTypeEnum.multipleFields ? [] : '') as PathValue<
          T,
          Path<T>
        >
      }
      rules={{ required: required }}
      render={({ field, fieldState: { invalid, error } }) => {
        if (option.type === InputTypeEnum.autocomplete && setValue)
          return (
            <FormControl fullWidth error={invalid} required={option.required}>
              <AutocompleteInputField
                setValue={setValue}
                invalid={invalid}
                error={error?.message}
                type={option.key as AutpocompleteTypeEnum}
                label={option.label}
                defaultValue={field.value}
                required={option.required}
              />
            </FormControl>
          );

        if (option.type === InputTypeEnum.select)
          return (
            <FormControl fullWidth error={invalid} required={option.required}>
              <InputLabel error={invalid}>{option.label}</InputLabel>
              <Select
                {...field}
                label={option.label}
                variant="outlined"
                sx={{ width: '100%' }}
                error={invalid}
                id={option.label}
                multiple={option.multiselect}
                required={option.required}
              >
                {option.select_options?.map((op, index) => {
                  if (typeof op === 'string')
                    return (
                      <MenuItem key={index} value={op}>
                        {op}
                      </MenuItem>
                    );
                  return (
                    <MenuItem key={index} value={op.id}>
                      {op.name}
                    </MenuItem>
                  );
                })}
              </Select>

              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </FormControl>
          );

        if (option.type === InputTypeEnum.image || option.type === InputTypeEnum.document)
          return (
            <>
              <UploadImage
                title={option.label}
                image={field.value}
                handleDelete={() => {
                  setValue && setValue(option.key as Path<T>, null as PathValue<T, Path<T>>);
                }}
                onDropCallback={image => {
                  if (!field.value && setValue) {
                    setValue(option.key as Path<T>, image as PathValue<T, Path<T>>);
                  } else {
                    setError &&
                      setError(option.key as Path<T>, {
                        type: 'custom',
                        message: t('global.image_error')
                      });
                  }
                }}
                isDocument={option.type === InputTypeEnum.document}
                error={invalid}
              />
              <Typography color={'error'} variant="body2">
                {error?.message}
              </Typography>
            </>
          );

        if (option.type === InputTypeEnum.password)
          return (
            <FormControl fullWidth error={invalid} required={option.required}>
              <InputLabel error={invalid}>{option.label}</InputLabel>
              <OutlinedInput
                label={option.label}
                type={showPassword ? 'text' : 'password'}
                {...field}
                error={invalid}
                required={option.required}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText color="error">{error?.message}</FormHelperText>
            </FormControl>
          );

        if (option.type === InputTypeEnum.date && setValue && setError)
          return (
            <DatePickerCustom
              invalid={invalid}
              option={option}
              error={error}
              field={field}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              defaultValue={field.value ? dayjs(field.value) : undefined}
            />
          );

        if (option.type === InputTypeEnum.checkbox)
          return (
            <FormControl fullWidth error={invalid} required={option.required}>
              <FormControlLabel
                control={<Checkbox checked={field.value} />}
                label={option.label}
                {...field}
              />
              <FormHelperText color="error">{error?.message}</FormHelperText>
            </FormControl>
          );

        if (option.type === InputTypeEnum.editor) {
          if (ejInstance && setValue) {
            return (
              <FormControl fullWidth error={invalid} required={option.required}>
                <Editor
                  ejInstance={ejInstance}
                  setEditorData={data =>
                    setValue(
                      (keyPrefix + option.key.toString()) as Path<T>,
                      data as PathValue<T, Path<T>>
                    )
                  }
                  editorData={field.value as OutputData}
                  uploadImage={option.uploadImage}
                />
                <FormHelperText color="error">{error?.message}</FormHelperText>
              </FormControl>
            );
          } else {
            return <div>missing values</div>;
          }
        }
        if (option.type === InputTypeEnum.multipleFields) {
          if (getValues && clearErrors && setValue) {
            return (
              <MultipleFields
                errors={control?._formState.errors}
                setValue={setValue}
                clearErrors={clearErrors}
                fieldKey={(keyPrefix + option.key.toString()) as Path<T>}
                getValues={getValues}
                title={option.label}
                control={control}
              />
            );
          } else {
            return <div>missing values</div>;
          }
        }

        if (option.type === InputTypeEnum.parser) {
          return (
            <Box>
              <Typography color={'gray'}>{t('voucher.overview')}</Typography>
              <Box className="editorView">
                <Typography variant="h5" fontWeight={'bold'}>
                  {title}
                </Typography>
                <Parser data={field.value as IParser} />
              </Box>
            </Box>
          );
        }

        return (
          <Box width={'100%'} display={'flex'} alignItems={'center'}>
            <TextField
              {...field}
              label={option.label}
              type={option.type}
              error={invalid}
              helperText={error?.message}
              id={option.label}
              required={option.required}
              multiline={option.multiline}
             
            />
            {option.helper && (
              <Tooltip title={option.helper}>
                <InfoOutlinedIcon sx={{ color: 'GrayText' }} />
              </Tooltip>
            )}
          </Box>
        );
      }}
    />
  );
};

export default FormInputField;
