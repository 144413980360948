import { Navigate, useNavigate } from 'react-router-dom';
//components
import FormInputField from '@components/FormInputField/FormInputField';
import { Box, Button, Grid, Typography } from '@mui/material';
import EditorJS from '@editorjs/editorjs';
import Loading from '@components/Loading/Loading';
//type
import { InputTypeEnum } from '@enum/formModal';
//hooks
import { useVoucher } from '@hooks/useVoucher/useVoucher';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
//api
import axiosInstance from '@services/config';

const CreateVoucherBody = () => {
  const {
    control,
    setValue,
    setError,
    handleSubmit,
    handleCreate,
    voucherId,
    singleVoucher,
    getValues,
    clearErrors,
    isLoadingSingle,
    isSubmitting
  } = useVoucher();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const ejInstance = useRef<EditorJS | undefined>();

  const uploadImage = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return await axiosInstance.patch(`v1/vouchers/${voucherId}/body-images`, formData).then(res => {
      return {
        success: 1,
        file: {
          url: res.data.imageUrl
        }
      };
    });
  };

  if (isLoadingSingle) return <Loading />;
  if (!singleVoucher) return <Navigate to={'/error-page'} />;
  return (
    <div className="container">
      <Typography variant="h5" fontWeight={'bold'}>
        {singleVoucher?.title}
      </Typography>

      <Typography color={'grey'}>{t('voucher.text_title')}</Typography>

      <Box component={'form'} onSubmit={handleSubmit(handleCreate)}>
        {getValues('data') !== undefined && (
          <Grid container spacing={1}>
            {[
              {
                key: 'data',
                type: InputTypeEnum.editor,
                label: t('voucher.text'),
                uploadImage: uploadImage
              },
              {
                key: 'data',
                type: InputTypeEnum.parser,
                label: t('voucher.text')
              }
            ]?.map((option, i) => (
              <Grid item xs={12} key={i}>
                <FormInputField
                  key={i}
                  option={option}
                  control={control}
                  setValue={setValue}
                  setError={setError}
                  ejInstance={ejInstance}
                  getValues={getValues}
                  clearErrors={clearErrors}
                />
              </Grid>
            ))}
          </Grid>
        )}

        <Box mt={2} display={'flex'} gap={1} justifyContent={'flex-end'}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => navigate(`/vouchers/${voucherId}/edit`)}
            disabled={isSubmitting}
          >
            {t('global.back')}
          </Button>

          <Button variant="contained" type="submit" color="primary">
            {t('global.accept')}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default CreateVoucherBody;
