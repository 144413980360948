import { JobType } from '@interfaces/job/job';
import { HitListType } from '@interfaces/hitList/hitList';
import { User } from '@interfaces/users/users';
import { TFunction } from 'i18next';
import { VoucherType } from '@interfaces/vouchers/vouchers';
import { CompanyType } from '@interfaces/company/company';
import { RadioType } from '@interfaces/radio/radio';
import { PodcastChannelType, PodcastType } from '@interfaces/podcast/podcast';
import { UserInterestsEnum } from '@enum/userEnum';

export const getCompanyProfileKeys = (
  t: TFunction
): {
  label: string;
  key: string; //keyof CompanyType;
}[] => {
  return [
    { label: t('user.company.name'), key: 'name' },
    { label: t('global.email'), key: 'email' },
    { label: t('global.phone'), key: 'phone' },
    { label: t('global.pib'), key: 'pib' },
    { label: t('global.industry'), key: 'industries' },
    { label: t('user.created_at'), key: 'createdAt' },
    { label: t('global.country'), key: 'countryNew' },
    { label: t('global.city'), key: 'city' },
    { label: t('global.address'), key: 'address' },
    { label: t('user.company.office'), key: 'office' },
    { label: t('user.company.founded'), key: 'founded' },
    { label: t('user.company.employees_number'), key: 'employeesNumber' },
    { label: t('global.website'), key: 'website' },
    { label: 'Instagram', key: 'socialNetworks.instagram' },
    { label: 'LinkedIn', key: 'socialNetworks.linkedin' },
    { label: 'Youtube', key: 'socialNetworks.youtube' }
  ];
};

export const getBusinessUserProfileKeys = (
  t: TFunction,
  single?: boolean
): {
  label: string;
  key: keyof User;
}[] => {
  if (single)
    return [
      { label: t('global.name_and_surname'), key: 'firstName' },
      { label: t('global.email'), key: 'email' },
      { label: t('global.phone'), key: 'phone' }
    ];
  return [
    { label: t('global.name_and_surname'), key: 'firstName' },
    { label: t('global.email'), key: 'email' },
    { label: t('global.phone'), key: 'phone' },
    { label: t('global.password'), key: 'id' }
  ];
};

export const getUserKeys = (
  t: TFunction,
  single?: boolean
): {
  label: string;
  key: keyof User | UserInterestsEnum;
}[] => {
  if (single)
    return [
      { label: t('global.account_type'), key: 'type' },
      { label: t('global.name_and_surname'), key: 'firstName' },
      { label: t('global.username'), key: 'username' },
      { label: t('global.email'), key: 'email' },
      { label: t('global.gender'), key: 'gender' },
      { label: t('global.date_of_birth'), key: 'dateOfBirth' },
      { label: t('global.country'), key: 'countryNew' },
      { label: t('global.city'), key: 'city' },
      { label: t('global.address'), key: 'address' },
      { label: t('user.email_verified'), key: 'emailVerifiedAt' },

      { label: t('global.relationship_status'), key: 'relationshipStatus' },
      { label: t('global.employment_status'), key: 'employmentStatus' },
      { label: t('global.job_seeker'), key: 'jobSeeker' },
      { label: t('global.disability'), key: 'disability' },
      { label: t('global.celebrations'), key: UserInterestsEnum.CELEBRATIONS },
      { label: t('global.genres'), key: UserInterestsEnum.GENRES },
      { label: t('global.rubrics'), key: UserInterestsEnum.RUBRICS },
      { label: t('global.industry'), key: UserInterestsEnum.INDUSTRIES },
      { label: t('global.interest'), key: UserInterestsEnum.INTERESTS }
    ];
  return [
    { label: t('global.account_type'), key: 'type' },
    { label: t('global.name_and_surname'), key: 'firstName' },
    { label: t('global.username'), key: 'username' },
    { label: t('global.email'), key: 'email' },
    { label: t('global.password'), key: 'id' },
    { label: t('global.gender'), key: 'gender' },
    { label: t('global.date_of_birth'), key: 'dateOfBirth' },
    { label: t('global.country'), key: 'countryNew' },
    { label: t('global.city'), key: 'city' },
    { label: t('global.address'), key: 'address' }
  ];
};

export const getJobKeys = (t: TFunction): { label: string; key: keyof JobType }[] => [
  { label: t('global.author'), key: 'company' },
  { label: t('global.position'), key: 'position' },
  { label: t('global.location'), key: 'location' },
  { label: t('global.created_at'), key: 'createdAt' },
  { label: t('job.expired_at'), key: 'expiredAt' },
  { label: t('global.type'), key: 'type' },
  { label: t('global.industry'), key: 'industry' },
  { key: 'tags', label: t('global.tags') },
  { key: 'hashTags', label: t('global.hash_tags') }
];

export const getHitListKeys = (t: TFunction): { label: string; key: keyof HitListType }[] => [
  { label: t('global.title'), key: 'name' },
  { label: t('global.status'), key: 'status' },
  { label: t('hit_list.song_number'), key: 'totalSongs' },
  { label: t('hit_list.vote_number'), key: 'totalVotes' },
  { label: t('hit_list.most_votes'), key: 'topSong' }
];

export const getVoucherKeysName = (t: TFunction): { label: string; key: keyof VoucherType }[] => [
  { key: 'title', label: t('global.title') },
  { key: 'companyName', label: t('voucher.company') },
  { key: 'location', label: t('global.location') },
  { key: 'couponLimit', label: t('voucher.coupon_limit') },
  { key: 'couponCounter', label: t('voucher.coupon_counter') },
  { key: 'status', label: t('global.status') },
  { key: 'targetGroup', label: t('voucher.target_group') },
  { key: 'type', label: t('voucher.discount_type') },
  { key: 'value', label: t('voucher.discount_value') },
  { key: 'validUntil', label: t('voucher.valid_until') },
  { key: 'createdAt', label: t('global.created_at') },
  { key: 'interests', label: t('global.categories') },
  { key: 'tags', label: t('global.tags') },
  { key: 'hashTags', label: t('global.hash_tags') },
  { key: 'shortDescription', label: t('global.short_description') }
];

export const getRadioKeysName = (t: TFunction): { label: string; key: keyof RadioType }[] => [
  { key: 'name', label: t('global.title') },
  {
    key: 'genres',
    label: t('global.genres')
  },
  {
    key: 'isDefault',
    label: t('radio.isDefault')
  },
  {
    key: 'streamSource',
    label: t('radio.streamSource')
  }
];

export const getPodcastKeysName = (t: TFunction): { label: string; key: keyof PodcastType }[] => [
  { key: 'title', label: t('global.title') },
  { key: 'type', label: t('global.type') },
  { key: 'url', label: t('global.link') },
  { key: 'duration', label: t('global.duration') }
];

export const getChannelKeysName = (
  t: TFunction
): { label: string; key: keyof PodcastChannelType }[] => [
  { key: 'title', label: t('global.title') },
  { key: 'url', label: t('global.link') },
  { key: 'publishedAt', label: t('podcast.channel.published') },
  { key: 'type', label: t('global.type') }
];
