import { ReactNode } from 'react';
import { TFunction } from 'i18next';
//icons
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import RadioOutlinedIcon from '@mui/icons-material/RadioOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WhatshotOutlinedIcon from '@mui/icons-material/WhatshotOutlined';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import MicNoneRoundedIcon from '@mui/icons-material/MicNoneRounded';
import { Add, AutoAwesomeMosaicOutlined } from '@mui/icons-material';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import { CompanyType } from '@interfaces/company/company';
import NotificationIcon from '@components/NotificationIcon/NotificationIcon';

type SidemenuListType = {
  label: string;
  icon: ReactNode;
  link: string;
  tourId: string;
};

export const getSidemenu = (
  role: string,
  t: TFunction,
  companies: CompanyType[] | undefined
): SidemenuListType[] => {
  if (role === 'admin')
    return [
      {
        label: t('sidebar.users'),
        icon: <SupervisedUserCircleOutlinedIcon />,
        link: '/users',
        tourId: 'users'
      },
      {
        label: t('sidebar.companies'),
        icon: <BusinessRoundedIcon />,
        link: '/companies',
        tourId: 'companies'
      },
      {
        label: t('sidebar.radio'),
        icon: <RadioOutlinedIcon />,
        link: '/radio',
        tourId: 'radio-stations'
      },

      {
        label: t('sidebar.hitLists'),
        icon: <WhatshotOutlinedIcon />,
        link: '/hit-lists',
        tourId: 'hit-lists'
      },
      {
        label: t('sidebar.jobs'),
        icon: <WorkOutlineOutlinedIcon />,
        link: '/jobs',
        tourId: 'jobs'
      },
      {
        label: t('sidebar.vouchers'),
        icon: <ConfirmationNumberOutlinedIcon />,
        link: '/vouchers',
        tourId: 'vouchers'
      },
      {
        label: t('sidebar.news'),
        icon: <NewspaperOutlinedIcon />,
        link: '/news',
        tourId: 'news'
      },
      {
        label: t('sidebar.podcasts'),
        icon: <MicNoneRoundedIcon />,
        link: '/channels',
        tourId: 'channels'
      },
      {
        label: t('sidebar.featured_posts'),
        icon: <StarBorderRoundedIcon />,
        link: '/featured-posts',
        tourId: 'featured-posts'
      },
      {
        label: t('sidebar.categories'),
        icon: <AutoAwesomeMosaicOutlined />,
        link: '/categories',
        tourId: 'categories'
      },
      {
        label: t('sidebar.faq'),
        icon: <InfoOutlinedIcon />,
        link: '/faq',
        tourId: 'faq'
      },
      {
        label: '',
        icon: <NotificationIcon />,
        link: '/notifications',
        tourId: 'notifications'
      }
    ];

  if (role === 'business') {
    if (companies && companies.length > 0) {
      return [
        ...companies.map(company => {
          return {
            label: company.name,
            icon: <div />,
            link: '/company/' + company.id,
            tourId: 'company'
          };
        }),
        {
          label: '',
          icon: <NotificationIcon />,
          link: '/notifications',
          tourId: 'notifications'
        }
      ];
    } else {
      return [
        {
          label: t('sidebar.notifications'),
          icon: <NotificationsNoneRoundedIcon />,
          link: '/notifications',
          tourId: 'notifications'
        },
        {
          label: t('login.add_company'),
          icon: <Add />,
          link: '/register/company',
          tourId: ''
        }
      ];
    }
  }

  return [];
};
