import { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { userAtom } from '@atoms/userAtom';
import { useAuth } from './useAuth';
import Loading from '@components/Loading/Loading';

const AuthProvider = ({
  role
}: {
  role?: 'business' | 'admin' | 'no-user';
}): ReactElement | null => {
  const user = useRecoilValue(userAtom);

  const { isAuth, isLoadingLogOut } = useAuth();

  if (isLoadingLogOut) return <Loading />;

  if (!isAuth) {
    if (
      !(
        location.pathname === '/login' ||
        location.pathname === '/register' ||
        location.pathname === '/reset-password-form' ||
        location.pathname === '/reset-password' ||
        location.pathname === '/recover-password'
      )
    )
      return <Navigate to="/login" replace />;
    return <Outlet />;
  }

  if (
    user &&
    (location.pathname === '/login' ||
      location.pathname === '/register' ||
      location.pathname === '/reset-password-form' ||
      location.pathname === '/reset-password' ||
      location.pathname === '/recover-password')
  ) {
    return <Navigate to={'/profile'} replace />;
  }

  if (role === 'no-user') return <Outlet />;

  if (!user) return <Navigate to="/login" replace />;

  if (location.pathname === '/register/company') return <Outlet />;

  if (user.type === 'business') {
    if (user.userCompanies) {
      if (user.userCompanies.length < 1) {
        return <Navigate to={'/register/company'} replace />;
      }
    }
  }

  if (!role) return <Outlet />;

  if (role === 'business' && user.type === 'business') {
    return <Outlet />;
  }

  if (role === 'admin' && user.type === 'admin') {
    return <Outlet />;
  }

  return <Navigate to={'error-page'} replace />;
};

export default AuthProvider;
