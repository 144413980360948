//hooks
import { useMemo } from 'react';
import { useUsers } from '@hooks/useUsers/useUsers';
//type
import { RoleEnum } from '@enum/roleEnum';
import { User } from '@interfaces/users/users';
import { MRT_ColumnDef } from 'material-react-table';
//components
import TableLayout from '@components/TableLayout/TableLayout';
import { Box, Typography } from '@mui/material';
import UserProfileInfoPage from '@pages/BusinessUserProfile/UserProfileInfoPage';
import { Navigate } from 'react-router-dom';
import Loading from '@components/Loading/Loading';
//helpers
import { getUserKeys } from '@helpers/profileFields/profileFields';
import dayjs from 'dayjs';
import { UserInterestsEnum } from '@enum/userEnum';
import { getTranslationValue } from '@helpers/utility';
import i18next from 'i18next';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';

const SingleUserPage = () => {
  const { t, user, isLoadingSingle, genres, rubrics, interests, industries, celebrations } =
    useUsers();

  const columns = useMemo<
    MRT_ColumnDef<{
      label: string;
      key: keyof User | UserInterestsEnum;
    }>[]
  >(
    () => [
      {
        accessorKey: 'label',
        header: '',
        Cell: ({ cell }) => (
          <Typography color={'primary'} variant="body2">
            {cell.getValue<string>()}
          </Typography>
        )
      },
      {
        accessorKey: 'key',
        header: '',
        Cell: ({ row }): string | undefined => {
          switch (row.original.key) {
            case 'employmentStatus':
              return user?.employmentStatus?.name;
            case 'relationshipStatus':
              return user?.relationshipStatus?.name;
            case 'type':
              return user?.type === null ? 'regular' : user?.type;
            case 'dateOfBirth':
              return user?.[row.original.key] !== null
                ? dayjs(user?.dateOfBirth).format('DD.MM.YYYY.')
                : undefined;
            case 'emailVerifiedAt':
              return user?.emailVerifiedAt !== null
                ? dayjs(user?.emailVerifiedAt).format('DD.MM.YYYY.')
                : undefined;
            case 'gender':
              return user?.[row.original.key] !== null ? t('global.' + user?.gender) : undefined;
            case 'firstName':
              return (user?.firstName ?? '') + ' ' + (user?.lastName ?? '');
            case 'countryNew':
              return user?.countryNew?.translations
                ? getTranslationValue(
                    user.countryNew.translations,
                    'name',
                    i18next.language as SupportedLanguagesEnum
                  )
                : '';
            case UserInterestsEnum.GENRES:
              return genres?.map(el => el.name).join(', ');
            case UserInterestsEnum.CELEBRATIONS:
              return celebrations?.map(el => el.name).join(', ');
            case UserInterestsEnum.RUBRICS:
              return rubrics?.map(el => el.name).join(', ');
            case UserInterestsEnum.INTERESTS:
              return interests?.map(el => el.name).join(', ');
            case UserInterestsEnum.INDUSTRIES:
              return industries?.map(el => el.name).join(', ');
            default:
              return user?.[row.original.key]?.toString();
          }
        }
      }
    ],
    [user, genres, rubrics, interests, industries, celebrations]
  );

  if (isLoadingSingle) {
    return <Loading />;
  }
  if (!user) {
    return <Navigate to={'/error-page'} replace />;
  }
  return (
    <Box>
      <Typography color={'primary'} variant="h5" fontWeight={'bold'}>
        {t('user.basic_info')}
      </Typography>
      {user.type === RoleEnum.BUSINESS ? (
        <UserProfileInfoPage single />
      ) : (
        <TableLayout columns={columns} data={getUserKeys(t, true)} rowCount={0} />
      )}
    </Box>
  );
};

export default SingleUserPage;
