import { NavLink, Navigate, Outlet } from 'react-router-dom';
// hooks
import { useRef, useState } from 'react';
import { useCompanyProfile } from '@hooks/useCompanyProfile/useCompanyProfile';
//compoents
import ProfileInfoCard from '@components/ProfileInfoCard/ProfileInfoCard';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import FormModal from '@components/FormModal/FormModal';
import { QRCodeCanvas } from 'qrcode.react';
import Loading from '@components/Loading/Loading';

const CompanyLayout = ({ single }: { single?: boolean }) => {
  const {
    isLoading,
    company,
    formData,
    onEdit,
    onEditHeroImageClick,
    onEditImageClick,
    onEditDescription,
    activeTab,
    t,
    isLoadingUpdate
  } = useCompanyProfile();

  const [showQR, setShowQR] = useState(false);

  const qrRef = useRef<HTMLDivElement | null>(null);

  const downloadQR = () => {
    if (qrRef.current) {
      let canvas = qrRef.current.querySelector('canvas');

      if (canvas) {
        let image = canvas.toDataURL('image/png');
        let anchor = document.createElement('a');
        anchor.href = image;
        anchor.download = `qr-code-${company?.name}.png`;
        anchor.click();
      }
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  if (!company) {
    return <Navigate to={'/error-page'} replace />;
  }
  return (
    <Box width={'100%'}>
      <Dialog open={showQR} onClose={() => setShowQR(false)}>
        <DialogContent>
          <Box width={250} p={5}>
            <div ref={qrRef}>
              <QRCodeCanvas
                id="qrCodeEl"
                size={800}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={JSON.stringify({ id: company.id, name: company.name })}
              />
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={downloadQR} variant="contained">
            {t('global.download_code')}
          </Button>
          <Button color="error" variant="outlined" onClick={() => setShowQR(false)}>
            {t('global.close')}
          </Button>
        </DialogActions>
      </Dialog>

      {formData && <FormModal {...formData} isLoading={isLoadingUpdate} />}

      <ProfileInfoCard
        title={`${company.name}`}
        subtitle={`${company.email}`}
        profileImage={company.image ?? null}
        handleEdit={onEdit}
        handleEditProfileImage={single ? undefined : onEditImageClick}
        handleEditBannerImage={single ? undefined : onEditHeroImageClick}
        bannerImage={company.coverImage}
        buttons={
          <>
            <Button
              onClick={() => setShowQR(true)}
              size="small"
              variant="contained"
              color="primary"
              data-tour="qr-button"
            >
              {t('global.generate_code')}
            </Button>
          </>
        }
      />

      <Box className="container">
        <Box mt={3} width={{ xs: '100%', md: '70%' }}>
          <Typography variant="subtitle2">
            {t('global.description')}: {company.description}
          </Typography>
          <Typography variant="subtitle2">
            {t('global.short_description')}: {company.shortDescription}
          </Typography>
          <Typography variant="subtitle2">
            {t('global.tags')}: {company.tags?.join(', ')}
          </Typography>
          <Typography variant="subtitle2">
            {' '}
            {t('global.hash_tags')}: {company.hashTags?.join(', ')}
          </Typography>
          {!single && (
            <Button
              variant="text"
              sx={{ textDecoration: 'underline', textTransform: 'none', pl: 0 }}
              onClick={onEditDescription}
              data-tour="edit-description-btn"
            >
              {t('user.company.edit_description')}
            </Button>
          )}
        </Box>

        <Box my={2}>
          <Tabs value={activeTab}>
            <Tab label={t('global.company_basic_info')} component={NavLink} to="" value={''} />
            <Tab
              label={t('global.projects')}
              component={NavLink}
              to="projects"
              value={'projects'}
            />
            <Tab
              label={t('global.jobs')}
              component={NavLink}
              to="jobs"
              value={'jobs'}
              data-tour="jobs-tab"
            />
          </Tabs>
        </Box>

        <Outlet />
      </Box>
    </Box>
  );
};

export default CompanyLayout;
