//enum
import { InputTypeEnum } from '@enum/formModal';
import { EmployeesNumberEnum, JobTypeEnum } from '@enum/companyEnum';
//type
import { MappedFieldsType } from '@interfaces/modalForm';
import { InterestsType } from '@interfaces/interests/interests';
import { getDiscountType, getTranslationValue } from '@helpers/utility';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';
import i18next, { TFunction } from 'i18next';
import { UpdateCompanyType } from '@interfaces/company/company';
import { PodcastTypeEnum } from '@interfaces/podcast/podcast';
//helpers
import dayjs from 'dayjs';
import CountriesAutocompleteField from '@components/CountriesAutocompleteField/CountriesAutocompleteField';
import { Control, Controller, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { CreateNewsType } from '@interfaces/news/news';
import { AutpocompleteTypeEnum } from '@enum/autocompleteEnum';
import { CountryLocalizedType } from '@interfaces/autocomplete/autocomplete';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import FormInputField from '@components/FormInputField/FormInputField';
import { ExportUsersParamsType } from '@interfaces/users/users';
import { CreateVoucherType, CurrencyType } from '@interfaces/vouchers/vouchers';
import Loading from '@components/Loading/Loading';

export const radioFields = (
  t: Function,
  genres: InterestsType[],
  isEdit: boolean = false,
  program: boolean = false
): MappedFieldsType[] => {
  if (program) {
    return [
      {
        key: 'file',
        type: InputTypeEnum.document,
        label: t('radio.form.program')
      }
    ];
  }
  const fields: MappedFieldsType[] = [
    {
      key: 'name',
      type: InputTypeEnum.text,
      label: t('radio.form.name'),
      required: true
    },
    {
      key: 'streamSource',
      type: InputTypeEnum.text,
      label: 'URL',
      required: true
    },
    {
      key: 'genres',
      type: InputTypeEnum.select,
      label: t('global.genres'),
      select_options: genres.map(g => {
        return {
          name: getTranslationValue(
            g.translations,
            'name',
            (localStorage.getItem('i18nextLng')?.toString() as SupportedLanguagesEnum) ??
              SupportedLanguagesEnum.ENGLISH
          ),
          id: g.id
        };
      }),
      multiselect: true
    },
    {
      key: 'lcn',
      type: InputTypeEnum.number,
      label: t('radio.lcn'),
      helper: t('radio.lcn_helper')
    },
    {
      key: 'isDefault',
      type: InputTypeEnum.checkbox,
      label: t('radio.isDefault')
    },
    {
      key: 'logo',
      type: InputTypeEnum.image,
      label: t('radio.form.logo')
    },
    {
      key: 'image',
      type: InputTypeEnum.image,
      label: t('radio.form.image')
    },
    {
      key: 'banner',
      type: InputTypeEnum.image,
      label: t('radio.form.banner')
    }
  ];
  if (!isEdit) {
    fields.push({
      key: 'file',
      type: InputTypeEnum.document,
      label: t('radio.form.program')
    });
  }

  return fields;
};

export const faqFields = (t: Function): MappedFieldsType[] => [
  {
    key: 'question',
    type: InputTypeEnum.text,
    label: t('faq.form.question'),
    required: true,
    translation: true
  },
  {
    key: 'answer',
    type: InputTypeEnum.text,
    label: t('faq.form.response'),
    multiline: true,
    required: true,
    translation: true
  }
];

export const hitListFields = (t: Function, isCreate: boolean): MappedFieldsType[] => [
  {
    key: 'name',
    type: InputTypeEnum.text,
    label: t('global.title'),
    required: true
  },
  {
    key: 'expiredAt',
    type: InputTypeEnum.date,
    label: t('hit_list.form.lasts_to'),
    required: isCreate,
    minDate: dayjs().add(1, 'day')
  },
  {
    key: 'file',
    type: InputTypeEnum.document,
    label: t('hit_list.form.song_list'),
    required: isCreate
  }
];

export const companyDecriprionFields = (t: TFunction): MappedFieldsType[] => [
  {
    key: 'description',
    type: InputTypeEnum.text,
    label: t('user.company.description'),
    multiline: true
  },
  {
    key: 'shortDescription',
    type: InputTypeEnum.text,
    multiline: true,
    label: t('global.short_description')
  },
  {
    key: 'hashTags',
    type: InputTypeEnum.multipleFields,
    label: t('global.hash_tags')
  },
  {
    key: 'tags',
    type: InputTypeEnum.multipleFields,
    label: t('global.tags')
  }
];

export const companyProfileFields = (
  t: Function,
  industries: InterestsType[]
): MappedFieldsType[] => [
  {
    key: 'email',
    type: InputTypeEnum.text,
    label: t('global.email')
  },
  {
    key: 'pib',
    type: InputTypeEnum.number,
    label: t('global.pib')
  },
  {
    key: 'industries',
    type: InputTypeEnum.select,
    label: t('global.industry'),
    select_options: industries.map(g => {
      return {
        name: getTranslationValue(
          g.translations,
          'name',
          (localStorage.getItem('i18nextLng')?.toString() as SupportedLanguagesEnum) ??
            SupportedLanguagesEnum.ENGLISH
        ),
        id: g.id
      };
    }),
    multiselect: true
  },

  {
    key: 'website',
    type: InputTypeEnum.text,
    label: t('global.website')
  },
  {
    key: 'address',
    type: InputTypeEnum.autocomplete,
    label: t('global.address')
  },
  {
    key: 'office',
    type: InputTypeEnum.autocomplete,
    label: t('user.company.office')
  },
  {
    key: 'founded',
    type: InputTypeEnum.date,
    label: t('user.company.founded'),
    maxDate: dayjs()
  },
  {
    key: 'employeesNumber',
    type: InputTypeEnum.select,
    label: t('user.company.employees_number'),
    select_options: Object.values(EmployeesNumberEnum)
  },
  {
    key: 'socialNetworks.instagram' as keyof UpdateCompanyType,
    type: InputTypeEnum.text,
    label: t('global.instagram')
  },
  {
    key: 'socialNetworks.linkedin' as keyof UpdateCompanyType,
    type: InputTypeEnum.text,
    label: t('global.linkedin')
  },
  {
    key: 'socialNetworks.youtube' as keyof UpdateCompanyType,
    type: InputTypeEnum.text,
    label: t('global.youtube')
  }
];

export const changePasswordFields = (t: Function): MappedFieldsType[] => [
  // {
  //   key: 'oldPassword',
  //   type: InputTypeEnum.password,
  //   label: t('user.old_password'),
  //   required: true
  // },
  {
    key: 'newPassword',
    type: InputTypeEnum.password,
    label: t('user.new_password'),
    required: true
  },
  {
    key: 'passwordConfirmation',
    type: InputTypeEnum.password,
    label: t('user.password_confirmation'),
    required: true
  }
];

export const userFields = (t: Function): MappedFieldsType[] => [
  {
    key: 'firstName',
    type: InputTypeEnum.text,
    label: t('global.name')
  },
  {
    key: 'lastName',
    type: InputTypeEnum.text,
    label: t('global.surname')
  },
  {
    key: 'gender',
    type: InputTypeEnum.select,
    label: t('global.gender'),
    select_options: [
      {
        id: 'undefined',
        name: 'NONE'
      },
      {
        id: 'male',
        name: t('global.male')
      },
      {
        id: 'female',
        name: t('global.female')
      }
    ]
  },
  {
    key: 'dateOfBirth',
    type: InputTypeEnum.date,
    label: t('global.date_of_birth'),
    maxDate: dayjs()
  },
  {
    key: 'address',
    type: InputTypeEnum.autocomplete,
    label: t('global.address')
  }
];

export const jobFields = (t: Function, industries: InterestsType[]): MappedFieldsType[] => [
  {
    key: 'name',
    type: InputTypeEnum.text,
    label: t('global.title'),
    required: true
  },
  {
    key: 'position',
    type: InputTypeEnum.text,
    label: t('global.position'),
    required: true
  },
  {
    key: 'type',
    type: InputTypeEnum.select,
    label: t('global.type'),
    select_options: Object.values(JobTypeEnum),
    required: true
  },
  {
    key: 'location',
    type: InputTypeEnum.autocomplete,
    label: t('global.location'),
    required: true
  },
  {
    key: 'expiredAt',
    type: InputTypeEnum.date,
    label: t('job.expired_at'),
    required: true,
    minDate: dayjs().add(1, 'day')
  },
  {
    key: 'industryId',
    type: InputTypeEnum.select,
    label: t('global.industry'),
    select_options: industries.map(g => {
      return {
        name: getTranslationValue(
          g.translations,
          'name',
          (localStorage.getItem('i18nextLng')?.toString() as SupportedLanguagesEnum) ??
            SupportedLanguagesEnum.ENGLISH
        ),
        id: g.id
      };
    }),
    required: true
  },
  {
    key: 'heroImage',
    type: InputTypeEnum.image,
    label: t('news.hero_image')
  },

  {
    key: 'description',
    type: InputTypeEnum.text,
    label: t('global.description'),
    multiline: true,
    required: true
  },
  {
    key: 'shortDescription',
    type: InputTypeEnum.text,
    label: t('global.short_description'),
    multiline: true
  },
  {
    key: 'hashTags',
    type: InputTypeEnum.multipleFields,
    label: t('global.hash_tags')
  },
  {
    key: 'tags',
    type: InputTypeEnum.multipleFields,
    label: t('global.tags')
  }
];

export const interestsFields = (t: Function): MappedFieldsType[] => [
  {
    key: 'name',
    type: InputTypeEnum.text,
    label: t('global.title'),
    translation: true
  },
  {
    key: 'file',
    type: InputTypeEnum.image,
    label: t('global.image')
  }
];

export const newsFields = (
  t: Function,
  rubrics: InterestsType[] | undefined = [],
  lng: string,
  setValue: UseFormSetValue<CreateNewsType>,
  invalid: boolean,
  defaultCountries?: CountryLocalizedType[]
): MappedFieldsType[] => [
  {
    key: 'title',
    type: InputTypeEnum.text,
    label: t('global.title')
  },
  {
    key: 'shortDescription',
    type: InputTypeEnum.text,
    label: t('news.short_description'),
    multiline: true
  },
  {
    key: 'rubrics',
    type: InputTypeEnum.select,
    label: t('global.rubrics'),
    multiselect: true,
    select_options: rubrics.map(r => {
      return {
        id: r.id,
        name: getTranslationValue(r.translations, 'name', lng as SupportedLanguagesEnum)
      };
    })
  },
  {
    key: 'country',
    type: InputTypeEnum.custom,
    label: t('news.short_description'),
    element: (
      <CountriesAutocompleteField
        setValue={setValue}
        invalid={invalid}
        label={t('global.country')}
        type={AutpocompleteTypeEnum.COUNTRY}
        multiple={true}
        defaultValue={defaultCountries}
      />
    )
  },
  {
    key: 'heroImage',
    type: InputTypeEnum.image,
    label: t('news.hero_image')
  },
  {
    key: 'featuredImage',
    type: InputTypeEnum.image,
    label: t('global.image')
  },
  {
    key: 'hashTags',
    type: InputTypeEnum.multipleFields,
    label: t('global.hash_tags')
  },
  {
    key: 'tags',
    type: InputTypeEnum.multipleFields,
    label: t('global.tags')
  }
];

export const voucherFields = (t: Function): MappedFieldsType[] => [
  {
    key: 'title',
    type: InputTypeEnum.text,
    label: t('global.title')
  },
  {
    key: 'hashTags',
    type: InputTypeEnum.multipleFields,
    label: t('global.hash_tags')
  },
  {
    key: 'tags',
    type: InputTypeEnum.multipleFields,
    label: t('global.tags')
  },
  {
    key: 'shortDescription',
    type: InputTypeEnum.text,
    label: t('global.short_description'),
    multiline: true
  }
];

export const podcastFields = (t: Function): MappedFieldsType[] => [
  {
    key: 'externalId',
    type: InputTypeEnum.text,
    label: t('podcast.form.externalId'),
    required: true
  },
  {
    key: 'type',
    type: InputTypeEnum.select,
    label: t('podcast.form.type'),
    select_options: Object.values(PodcastTypeEnum),
    required: true
  }
];

export const podcastChannelFields = (t: Function): MappedFieldsType[] => [
  {
    key: 'externalId',
    type: InputTypeEnum.text,
    label: t('podcast.channel.form.externalId'),
    required: true,
    helper: t('podcast.channel.helper')
  },
  {
    key: 'type',
    type: InputTypeEnum.select,
    label: t('podcast.channel.form.type'),
    select_options: ['YOUTUBE'],
    required: true
  }
];

export const exportUsersOptionsFields = (
  t: Function,
  control: Control<ExportUsersParamsType>
): MappedFieldsType[] => {
  const keys = [
    'countryNew',
    'city',
    'address',
    'phone',
    'gender',
    'dateOfBirth',
    'employmentStatus',
    'relationshipStatus',
    'jobSeeker',
    'disability',
    'birthCountry',
    'birthPlace',
    'createdAt',
    'userCompanies'
  ];

  const options = keys.map((key: string) => {
    return {
      key: key,
      type: InputTypeEnum.checkbox,
      label: t(`user.export.${key}`)
    };
  });

  return [
    {
      key: 'relation',
      type: InputTypeEnum.select,
      label: t(`user.export.relation`),
      select_options: [
        { id: 'interests', name: t(`global.interest`) },
        { id: 'rubrics', name: t(`global.rubrics`) },
        { id: 'genres', name: t(`global.genres`) },
        { id: 'industries', name: t(`global.industry`) },
        { id: 'celebrations', name: t(`global.celebrations`) }
      ]
    },
    {
      key: 'custom',
      type: InputTypeEnum.custom,
      label: 'custom',
      element: (
        <Box p={2}>
          <Typography color={'primary'} fontWeight={'bold'} variant="subtitle1">
            {t(`user.export.export_title2`)}
          </Typography>
          <Typography color={'GrayText'} variant="body2">
            {t(`user.export.export_subtitle2`)}
          </Typography>

          <Grid container>
            {options.map((option, index) => (
              <Grid item key={index} xs={12} sm={4}>
                <FormInputField option={option} control={control} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )
    }
  ];
};

export const voucherAllFields = (
  t: TFunction,
  interests: InterestsType[]
) => {
  return [
    {
      key: 'targetGroup',
      type: InputTypeEnum.select,
      label: t('voucher.target_group'),
      select_options: [{ id: 'all', name: t('global.all') }]
    },
    {
      key: 'location',
      type: InputTypeEnum.autocomplete,
      label: t('global.location')
    },
    {
      key: 'couponLimit',
      type: InputTypeEnum.number,
      label: t('voucher.coupon_limit')
    },
    {
      key: 'interests',
      type: InputTypeEnum.select,
      label: t('global.categories'),
      select_options: interests.map(el => {
        return {
          id: el.id,
          name: getTranslationValue(
            el.translations,
            'name',
            i18next.language as SupportedLanguagesEnum
          )
        };
      }),
      multiselect: true
    },
    {
      key: 'image',
      type: InputTypeEnum.image,
      label: t('global.image')
    },
    {
      key: 'heroImage',
      type: InputTypeEnum.image,
      label: t('global.hero_image')
    }
  ];
};
