import React, { ReactNode } from 'react';
//components
import { Box, Grid, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormInputField, { FormInputFieldPropsType } from '@components/FormInputField/FormInputField';
//types
import { MappedFieldsType } from '@interfaces/modalForm';
//helpers
import { SupportedLanguagesEnum } from '@src/translation/languages.config';
import { InputTypeEnum } from '@enum/formModal';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

export type LanguageTabsPropsType<T extends {}> = Omit<FormInputFieldPropsType<T>, 'option'> & {
  translationFields?: MappedFieldsType[];
  children?: ReactNode | ReactNode[];
};

const LanguageTabs = <T extends {}>({
  control,
  setValue,
  setError,
  translationFields,
  clearErrors,
  getValues,
  ejInstance
}: LanguageTabsPropsType<T>) => {
  const [tabValue, setTabValue] = React.useState<string>(
    localStorage.getItem('i18nextLng') ?? SupportedLanguagesEnum.ENGLISH
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography color={'error'}>{control?._formState.errors.root?.message}</Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
          {Object.values(SupportedLanguagesEnum).map(lng => (
            <Tab label={lng} key={lng} value={lng} />
          ))}
        </Tabs>
      </Box>
      {Object.values(SupportedLanguagesEnum).map(lng => (
        <CustomTabPanel value={tabValue} index={lng} key={lng}>
          <Grid container spacing={1}>
            {translationFields?.map((option, i) => (
              <Grid item xs={12} key={i}>
                {option.type === InputTypeEnum.custom ? (
                  option.element
                ) : (
                  <FormInputField
                    key={i}
                    option={option}
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    keyPrefix={`translations.${lng}.`}
                    required={true}
                    clearErrors={clearErrors}
                    getValues={getValues}
                    ejInstance={ejInstance}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default LanguageTabs;
