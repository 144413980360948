import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
//components
import { Box, Button, TextField, Tooltip, Typography } from '@mui/material';
import FormModal from '@components/FormModal/FormModal';
import TableLayout from '@components/TableLayout/TableLayout';
import { NavLink, useNavigate } from 'react-router-dom';
import Loading from '@components/Loading/Loading';
//icons
import AddRoundedIcon from '@mui/icons-material/AddRounded';
//type
import { MRT_ColumnDef } from 'material-react-table';
import { NewsType } from '@interfaces/news/news';
import { CountryLocalizedType } from '@interfaces/autocomplete/autocomplete';
//hooks
import { useNews } from '@hooks/useNews/useNews';
import { getTranslationValue } from '@helpers/utility';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';
import { SANITY_URL } from '@helpers/env.config';

const NewsPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const {
    news,
    popup,
    pagination,
    setPagination,
    rowCount,
    isLoadingAll,
    columnFilters,
    setColumnFilters,
    rubrics,
    isSubmitting,
    countrySearch,
    suggestedCountries,
    isLoadingSuggestion,
    handleSearchChange,
    handleInputChange
  } = useNews();

  const columns = useMemo<MRT_ColumnDef<NewsType>[]>(
    () => [
      {
        accessorKey: 'title',
        header: t('global.title'),
        Cell: ({ cell, row }) => (
          <Tooltip title={cell.getValue<string>()}>
            <NavLink to={row.original.id}>
              <Typography variant="body2">{cell.getValue<string>()}</Typography>
            </NavLink>
          </Tooltip>
        )
      },
      {
        accessorKey: 'rubrics',
        header: t('global.rubrics'),
        Cell: ({ row }) => row.original.rubrics?.map(r => r.name)?.join(', '),
        filterVariant: 'select',
        filterSelectOptions: rubrics.map(r => {
          return {
            text: getTranslationValue(
              r.translations,
              'name',
              i18n.language as SupportedLanguagesEnum
            ),
            value: r.id
          };
        })
      },
      {
        accessorKey: 'country',
        header: t('global.country'),
        Cell: ({ row }) => {
          return row.original.countries
            ?.map(
              r =>
                r.translations &&
                getTranslationValue(r.translations, 'name', i18n.language as SupportedLanguagesEnum)
            )
            ?.join(', ');
        },
        filterVariant: 'autocomplete',
        manualFilter: true,
        muiFilterAutocompleteProps: (props: any) => {
          return {
            renderInput: params => (
              <TextField InputProps={params.InputProps} inputProps={params.inputProps} />
            ),
            options: suggestedCountries?.data ? suggestedCountries.data : [],
            freeSolo: true,
            loading: countrySearch.length === 0 ? false : isLoadingSuggestion,
            loadingText: <Loading />,
            inputValue: countrySearch,
            onChange: handleInputChange,
            getOptionLabel: (option: CountryLocalizedType | string) =>
              typeof option !== 'string' ? option.name : option,
            onInputChange: handleSearchChange,
            isOptionEqualToValue: (option: CountryLocalizedType, value: CountryLocalizedType) =>
              option.name === value.name,
            filterOptions: (options, state) => options,
            noOptionsText: 'no options'
          };
        }
      },
      {
        accessorKey: 'hashTags',
        header: t('news.hash_tags'),
        Cell: ({ row }) => row.original.hashTags?.join(', '),
        enableColumnFilter: false
      }
    ],
    [news, t, rubrics, countrySearch, suggestedCountries]
  );

  return (
    <Box className="container">
      {popup && (
        <FormModal
          title={popup.title}
          subtitle={popup.subtitle}
          open={true}
          control={popup.control}
          fields={popup.fields}
          onCancel={popup.onCancel}
          onSubmit={popup.onSubmit}
          setValue={popup.setValue}
          buttonText={popup.buttonText}
          variant={popup.variant}
          setError={popup.setError}
          isLoading={isSubmitting}
        />
      )}
      <Box mt={1}>
        <Typography fontWeight={'bold'} variant="h4">
          {t('global.news')}
        </Typography>
        <Typography variant="subtitle1" color={'text.secondary'}>
          {t('news.page_subtitle')}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} gap={2} mb={2}>
        <NavLink to={SANITY_URL} target="_blank" rel="noopener noreferrer">
          <Button variant="outlined" onClick={() => {}}>
            {t('news.add_news')} <AddRoundedIcon />
          </Button>
        </NavLink>
      </Box>
      <Box mt={2}>
        <TableLayout
          columns={columns}
          data={news}
          rowCount={rowCount}
          pagination={pagination}
          setPagination={setPagination}
          handleEdit={(news: NewsType) => navigate(news.id)}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          isLoading={isLoadingAll}
        />
      </Box>
    </Box>
  );
};

export default NewsPage;
