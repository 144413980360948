//hooks
import { useTranslation } from 'react-i18next';
import { useVoucher } from '@hooks/useVoucher/useVoucher';
import { useNavigate } from 'react-router-dom';
//components
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from '@mui/material';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import AutocompleteInfiniteScroll from '@components/AutocompleteInfiniteScroll/AutocompleteInfiniteScroll';
import DatePickerCustom from '@components/DatePicker/DatePicker';
import Loading from '@components/Loading/Loading';
//helpers
import { getDiscountType, dayjs } from '@helpers/utility';
import { voucherAllFields, voucherFields } from '@helpers/formFields/formFields';
import FormInputField from '@components/FormInputField/FormInputField';
//enum
import { InputTypeEnum } from '@enum/formModal';
import { CreateVoucherType, CurrencyType } from '@interfaces/vouchers/vouchers';

const CreateVoucherPage = () => {
  const {
    control,
    setValue,
    setError,
    handleSubmit,
    handleCreate,
    currencies,
    voucherId,
    singleVoucher,
    errors,
    isValid,
    getValues,
    clearErrors,
    isLoadingCompanies,
    isFetchingCompanies,
    hasNextPageCompanies,
    fetchNextPageCompanies,
    interests,
    companies,
    setSearch,
    isSubmitting,
    reset
  } = useVoucher();

  const { t } = useTranslation();

  const navigate = useNavigate();

  if (voucherId && !singleVoucher) return <Loading />;

  return (
    <Box component="form" onSubmit={handleSubmit(handleCreate)}>
      <Box display={'flex'} flexDirection={'column'} gap={1} mb={1}>
        {voucherId ? (
          <Controller
            name={'companyId'}
            control={control}
            defaultValue={voucherId ? singleVoucher?.companyName : ''}
            rules={{ required: true }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl fullWidth error={invalid}>
                <InputLabel>{t('voucher.company')}</InputLabel>
                <Select
                  label={t('voucher.company')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  id={'voucher.company'}
                  defaultValue={''}
                  {...field}
                  disabled={!!voucherId}
                >
                  <MenuItem value={singleVoucher?.companyName}>
                    {singleVoucher?.companyName}
                  </MenuItem>
                </Select>

                <FormHelperText color="error">{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        ) : (
          <AutocompleteInfiniteScroll
            name="companyId"
            control={control}
            required={true}
            options={companies}
            label={t('voucher.company')}
            infinityOptions={{
              isLoading: isLoadingCompanies,
              isFetching: isFetchingCompanies,
              hasNextPage: hasNextPageCompanies,
              fetchNextPage: fetchNextPageCompanies
            }}
            setSearch={setSearch}
            setValue={setValue}
          />
        )}
        <Controller
          name={'validUntil'}
          control={control}
          defaultValue={singleVoucher?.validUntil ? dayjs(singleVoucher.validUntil) : undefined}
          render={({ field, fieldState: { invalid, error } }) => (
            <DatePickerCustom
              invalid={invalid}
              option={{
                key: 'validUntil',
                label: t('voucher.valid_until'),
                type: InputTypeEnum.date,
                minDate: dayjs().add(1, 'day')
              }}
              error={error}
              field={field as ControllerRenderProps<CreateVoucherType>}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              defaultValue={singleVoucher?.validUntil ? dayjs(singleVoucher.validUntil) : undefined}
            />
          )}
        />
        <Box display={'flex'} gap={1}>
          <Controller
            name={'type'}
            defaultValue="fixed"
            rules={{ required: true }}
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl fullWidth error={invalid}>
                <InputLabel>{t('voucher.discount_type')}</InputLabel>
                <Select
                  label={t('voucher.discount_type')}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  id={t('voucher.discount_type')}
                  {...field}
                >
                  {getDiscountType(t).map((c, index) => (
                    <MenuItem key={index} value={c.value}>
                      {c.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText color="error">{error?.message}</FormHelperText>
              </FormControl>
            )}
          />

          <Controller
            name={'value.value'}
            control={control}
            defaultValue={0}
            rules={{ required: true }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                label={t('voucher.discount_value')}
                {...field}
                sx={{ pl: 0 }}
                error={invalid || !!errors.value?.currency}
                helperText={error?.message}
                InputProps={{
                  sx: { paddingLeft: 0 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Controller
                        name={'value.currency'}
                        control={control}
                        // defaultValue={currencies?.[0] ? JSON.stringify(currencies[0]) : ''}
                        defaultValue={currencies?.[0] ? currencies[0].id : ''}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <FormControl fullWidth error={invalid}>
                            <Select
                              label={t('voucher.discount_value')}
                              sx={{
                                width: '100%',
                                pb: '0.5px',

                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderRadius: 0,
                                  borderLeft: 'none',
                                  borderTop: 'none',
                                  borderBottom: 'none'
                                }
                              }}
                              id={'currency'}
                              {...field}
                            >
                              {currencies?.map((c: CurrencyType, index) => (
                                <MenuItem
                                  key={index}
                                  value={c.id}
                                  //value={JSON.stringify(c)}
                                >
                                  {c.symbol}
                                </MenuItem>
                              ))}
                            </Select>

                            <FormHelperText color="error">{error?.message}</FormHelperText>
                          </FormControl>
                        )}
                      />
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        </Box>
      </Box>

      <Grid container spacing={1}>
        {voucherAllFields(t, interests)?.map((option, i) => (
          <Grid item xs={12} key={i}>
            <FormInputField
              key={i}
              option={option}
              control={control}
              setValue={setValue}
              setError={setError}
              getValues={getValues}
              clearErrors={clearErrors}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={1}>
        {voucherFields(t)?.map((option, i) => (
          <Grid item xs={12} key={i}>
            <FormInputField
              key={i}
              option={option}
              control={control}
              setValue={setValue}
              setError={setError}
              getValues={getValues}
              clearErrors={clearErrors}
            />
          </Grid>
        ))}
      </Grid>

      <Box mt={2} display={'flex'} gap={1} justifyContent={'flex-end'}>
        <Button variant="outlined" color="error" onClick={() => navigate(-1)}>
          {t('global.cancel')}
        </Button>
        <Tooltip title={isValid ? '' : t('global.required_message')} color="error">
          <Button variant="contained" type="submit" color="primary" disabled={isSubmitting}>
            {t('global.next')}
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default CreateVoucherPage;
